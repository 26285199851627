import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import ButtonPage from "./ButtonPage";
import Footer from "./Footer";
import Title from "./Title";

const SplashPageNavigation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonPagesLinks = [
    {
      id: 1,
      name: "Term Rewriting",
      url: "/rewriting/temp/term-rewriting/introduction",
      color: "#1f9f85",
    },
    {
      id: 2,
      name: "Lambda Calculus",
      url: "/rewriting/temp/lambda-calculus/introduction",
      color: "#1d4778",
    },
    {
      id: 3,
      name: "Logic and Computation",
      url: "/rewriting/temp/logic-and-computation/introduction",
      color: "#243546",
    },
  ];

  const otherPagesLinks = [
    {
      id: 4,
      name: "Open problems",
      url: "/rewriting/temp/open-problems",
    },
    {
      id: 5,
      name: "Researchers",
      url: "/rewriting/temp/researchers",
    },
    {
      id: 6,
      name: "Conferences",
      url: "/rewriting/temp/conferences",
    },
    // {
    //   id: 7,
    //   name: "Mailing lists",
    //   url: "/rewriting/temp/mailing-lists",
    // },
    {
      id: 8,
      name: "Languages",
      url: "/rewriting/temp/languages",
    },
    {
      id: 9,
      name: "Free tools",
      url: "/rewriting/temp/free-tools",
    },
    {
      id: 10,
      name: "Industrial tools",
      url: "/rewriting/temp/industrial-tools",
    },
    {
      id: 11,
      name: "Companies",
      url: "/rewriting/temp/companies",
    },
  ];

  return (
    <div className="splash-page-navigation-container">
      <Title title="Formal Structures for Computation and Deduction" />
      <div className="fscd-conference-banner">
        <div className="fscd-background"></div>
        <div className="content"></div>
      </div>
      <div className="main">
        <p>Text explaining the general contents of this web page.</p>
        <div className="first-container">
          {buttonPagesLinks.map(({ id, name, url, color }) => (
            <ButtonPage key={id} name={name} url={url} color={color} />
          ))}
        </div>
        <div className="second-container">
          {otherPagesLinks.map(({ id, name, url }) => (
            <NavLink key={id} to={url}>
              {name}
            </NavLink>
          ))}
        </div>
        <ButtonPage
          name="Intranet"
          url="/rewriting/login"
          color="#E9E9E9"
        />
      </div>
      <Footer />
    </div>
  );
};

export default SplashPageNavigation;
