import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import MediumCard from "../../../components/MediumCard";
import {
  Box,
  Breadcrumbs,
  Pagination,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import Navigation from "../../../components/temp/Navigation";
import { Link } from "react-router-dom";

const CoursesPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const fetchData = () => {
    setLoading(true);
    fetch("http://localhost:5000/api/course").then((response) => {
      response.json().then((data) => {
        setData(data);
      });
      response.ok && setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = data.filter((item) =>
    item.tags?.some((tag) =>
      tag.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="container">
      <Navigation name="Courses" />
      <div
        className="main"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            pt: 2,
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              "& .MuiBreadcrumbs-separator": {
                color: "#006789",
              },
            }}
          >
            <Link
              underline="hover"
              color="inherit"
              to="/rewriting/resources/"
              style={{
                textDecoration: "none",
              }}
            >
              <Typography
                sx={{
                  color: "#006789",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Resources
              </Typography>
            </Link>
            <Typography color="text.primary">Courses</Typography>
          </Breadcrumbs>
        </Box>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Courses
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            width: "600px",
            "@media (max-width: 650px)": {
              width: "100%",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            label="Search by tag"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 5,
              },
              "& .MuiInputLabel-root": {
                color: "#006789",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#006789",
              },
              //background
              "& .MuiOutlinedInput-input": {
                backgroundColor: "#fff",
                borderRadius: 5,
              },
            }}
          />
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Skeleton variant="rounded" />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
            </Box>
          ) : (
            <>
              {filteredData.length === 0 ? (
                <Typography variant="h6" sx={{ mt: 2, width: "100%" }}>
                  No results found
                </Typography>
              ) : (
                <>
                  {filteredData
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map((course) => (
                      <MediumCard
                        key={course._id}
                        name={course.name}
                        description={course.description}
                        tags={course.tags}
                        website={course.link}
                      />
                    ))}
                  <Pagination
                    count={Math.ceil(filteredData.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    sx={{ mt: 2 }}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default CoursesPage;
