import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navigation from "../../components/temp/Navigation";
import Typography from "@mui/material/Typography";

import { Box, Skeleton } from "@mui/material";
import API_URL from "../../constants";
const IntroductionPage = () => {
  //update 20/06
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  function fetchData() {
    setLoading(true);
    fetch(API_URL+"text/introduction").then(
      (response) => {
        response.json().then((data) => {
          data.length !== 0 && setData(data[0]);
        });
        response.ok && setLoading(false);
      }
    );
  }

  return (
    <div className="container">
      <Navigation name="Introduction" />
      <div
        className="main"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
          </Box>
        ) : (
          <>
            {data.length === 0 ? (
              <Typography variant="h6" sx={{ mt: 2, width: "100%" }}>
                No results found
              </Typography>
            ) : (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
            )}
          </>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default IntroductionPage;
