import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API_URL from '../constants';
import { Alert, Box, Typography } from '@mui/material'; // Import MUI components for alerts

const ConfirmAccountPage = () => {
    const { token } = useParams(); // Get the token from URL params
    const navigate = useNavigate();
    const [message, setMessage] = useState(""); // State to manage messages
    const [messageType, setMessageType] = useState(""); // Type of message for display (success, error)

    useEffect(() => {
        const confirmAccount = async () => {
            try {
                const response = await fetch(`${API_URL}/confirm/${token}`, { // Correctly use template literal for URL
                    method: 'GET'
                });

                if (response.ok) {
                    // alert('Account successfully confirmed. You can now log in.');
                    // setMessageType('success'); // Message type for display
                    setTimeout(() => navigate('/rewriting/login'), 3000); // Redirect after successful confirmation
                } else {
                    const data = await response.json();
                    setMessage(data.message || 'Failed to confirm the account');
                    setMessageType('error'); // Message type for display
                }
            } catch (err) {
                console.error('Error during account confirmation:', err);
                setMessage('An error occurred. Please try again.');
                setMessageType('error'); // Message type for display
            }
        };

        confirmAccount();
    }, [token, navigate]);

    return (
        <Box sx={{ p: 2, textAlign: 'center' }}>
            {message && (
                <Alert severity={messageType} sx={{ mb: 2 }}>
                    {message}
                </Alert>
            )}
            <Typography variant="h6">Confirming your account...</Typography>
        </Box>
    );
};

export default ConfirmAccountPage;
