import Box from "@mui/material/Box";
import IntranetNavBar from "../../components/IntranetNavBar";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Button, IconButton, LinearProgress, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Chip } from "@mui/material";
import DataTable from "../../components/DataTable";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditEntry from "../../components/EditEntry";
import DeleteEntry from "../../components/DeleteEntry";
import AddEntry from "../../components/AddEntry";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import API_URL from "../../constants";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const ManageAccountsPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [wantToDelete, setWantToDelete] = useState(false);
  const [wantToChangeRole, setWantToChangeRole] = useState(false);
  const [dataEdited, setDataEdited] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newRole, setNewRole] = useState("user");
  // const [currentRole, setCurrentRole] = useState(""); // État pour stocker le rôle actuel avant le changement

  const name = "user/random-password";
  const [counter, setCounter] = useState(0);

  const fetchData = () => {
    setLoading(true);
    fetch(`${API_URL}user/`).then((response) => {
      response.json().then((data) => {
        setData(data);
        setLoading(false); // Assurez-vous de ne pas oublier cette ligne
      }).catch(() => {
        setLoading(false); // Assurez-vous de gérer les erreurs
      });
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [selectedUser]); // Recharger les données à chaque changement du compteur

  const updateRole = (userId, newRole) => {
    fetch(`${API_URL}user/${userId}/role`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ role: newRole }),
    })
    .then((response) => response.json())
    .then(() => {
      setCounter(prevCounter => prevCounter + 1); // Incrémente le compteur pour recharger les données
    })
    .catch((error) => {
      console.error('Error updating role:', error);
    });
  };

  const handleRoleChange = () => {
    updateRole(selectedUser._id, newRole);
    setWantToChangeRole(false);
  };

  const filteredData = data.map((row, index) => ({
    id: index + 1,
    _id: row._id,
    username: row.username,
    role: row.role,
  }));

  const entries = [
    {
      name: "username",
      displayname: "Email",
      type: "email",
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 30,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "username",
      headerName: "Email",
      width: 300,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {params.value === "admin" ? (
            <Chip
              icon={<AdminPanelSettingsIcon />}
              label="Admin"
              color="primary"
              size="small"
            />
          ) : (
            <Chip
              icon={<PersonIcon />}
              label="User"
              color="default"
              size="small"
            />
          )}
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "",
      renderCell: (params) => (
        <Tooltip title="Edit">
          <IconButton
            style={{ color: "#1565C0" }}
            onClick={(evt) => {
              evt.preventDefault();
              setWantToEdit(true);
              setWantToDelete(false);
              setCounter((prevCounter) => prevCounter + 1);
              setDataEdited(filteredData[params.row.id - 1]);
            }}
          >
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "delete",
      headerName: "",
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
            style={{ background: "#ffcdcd" }}
            onClick={(evt) => {
              evt.preventDefault();
              setWantToDelete(true);
              setWantToEdit(false);
              setCounter((prevCounter) => prevCounter + 1);
              setDataEdited(filteredData[params.row.id - 1]);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "changeRole",
      headerName: "",
      renderCell: (params) => (
        <Tooltip title="Change Role">
          <IconButton
            style={{ color: "#1565C0" }}
            onClick={(evt) => {
              evt.preventDefault();
              handleOpenChangeRoleDialog(filteredData[params.row.id - 1]);
            }}
          >
            <AccountCircleIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const handleOpenChangeRoleDialog = (user) => {
    setSelectedUser(user);
    // setCurrentRole(user.role);
    setNewRole(user.role);
    setWantToChangeRole(true);
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <IntranetNavBar entitled="Manage accounts" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "100%",
          overflowX: "scroll",
        }}
      >
        <DrawerHeader />
        {wantToEdit && (
          <EditEntry
            name={"user/email"}
            entries={entries}
            key={"editentry" + counter}
            data={dataEdited}
            onSuccess={fetchData}
          />
        )}
        {wantToDelete && (
          <DeleteEntry
            name={"user"}
            entries={entries}
            key={"editentry" + counter}
            data={dataEdited}
            onSuccess={fetchData}
          />
        )}
        {loading ? (
          <>
            Loading data...
            <LinearProgress />{" "}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <AddEntry name={name} entries={entries} onSuccess={fetchData} />
          </Box>
        )}
        <DataTable name={name} columns={columns} rows={filteredData} key={counter} />
      </Box>

      <Dialog open={wantToChangeRole} onClose={() => setWantToChangeRole(false)}>
        <DialogTitle>Change Role</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the new role for the user.
          </DialogContentText>
          <Select
            value={newRole || ""}
            onChange={(event) => setNewRole(event.target.value)}
            fullWidth
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWantToChangeRole(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRoleChange} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageAccountsPage;
