import Box from "@mui/material/Box";
import IntranetNavBar from "../../../components/IntranetNavBar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import AddEntry from "../../../components/AddEntry";
import Button from "@mui/material/Button";
// import API_URL from "../../../constants";

import {
  Breadcrumbs,
  Chip,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
} from "@mui/material";
import EditEntry from "../../../components/EditEntry";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteEntry from "../../../components/DeleteEntry";
import DrawerHeader from "../../../components/DrawerHeader";
import { Link, NavLink } from "react-router-dom";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import API_URL from "../../../constants";
const EditConferencesPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [wantToDelete, setWantToDelete] = useState(false);
  const [wantToEditTypeConf, setWantToEditTypeConf] = useState(false);
  const [wantToDeleteTypeConf, setWantToDeleteTypeConf] = useState(false);
  const [counter, setCounter] = useState(0);
  const [dataEdited, setDataEdited] = useState([]);
  const [wantOpenConference, setWantOpenConference] = useState(false);
  const [conferenceIndex, setConferenceIndex] = useState(0);
  const [conferenceType, setConferenceType] = useState("");
  const [indexTypeConference, setIndexTypeConference] = useState("");
  
  const name = "conference";
  const [id, setId] = useState("");

  const [upcomingAccordionExpanded, setUpcomingAccordionExpanded] =
    useState(false);
  const [pastAccordionExpanded, setPastAccordionExpanded] = useState(false);

  const fetchData = () => {
    setLoading(true);
    fetch(API_URL+ name).then((response) => {
      response.json().then((data) => {
        setData(data);
      });
      response.ok && setLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    setWantToEdit(false);
    setWantToDelete(false);
  }, []);

  const filteredData = data.map((row, index) => ({
    id: index + 1,
    _id: row._id,
    name: row.name,
    description: row.description,
    website: row.website,
    tags: row.tags,
    past: JSON.stringify(
      row.past.map((event) => ({
        name: event.name,
        url: event.url,
        _id: event._id,
      }))
    ),
    upcoming: JSON.stringify(
      row.upcoming.map((event) => ({
        name: event.name,
        url: event.url,
        _id: event._id,
      }))
    ),
  }));

  const editfilteredData = data.map((row, index) => ({
    id: index + 1,
    _id: row._id,
    name: row.name,
    description: row.description,
    website: row.website,
    tags: row.tags,
    past: row.past.map((event) => ({
      name: event.name,
      url: event.url,
      _id: event._id,
    })),
    upcoming: row.upcoming.map((event) => ({
      name: event.name,
      url: event.url,
      _id: event._id,
    })),
  }));

  const entries = [
    {
      name: "name",
      displayname: "Name",
      type: "short-text",
    },
    {
      name: "description",
      displayname: "Description",
      type: "multiline",
    },
    {
      name: "website",
      displayname: "Website",
      type: "short-text",
    },
    {
      name: "tags",
      displayname: "Tag",
      type: "short-text",
    },
    {
      name: "past",
      displayname: "Past",
      type: "invisible",
    },
    {
      name: "upcoming",
      displayname: "Upcoming",
      type: "invisible",
    },
  ];

  function entriesType(type) {
    if (type === "past") {
      return [
        {
          name: "name",
          displayname: "Name",
          type: "invisible",
        },
        {
          name: "description",
          displayname: "Description",
          type: "invisible",
        },
        {
          name: "website",
          displayname: "Website",
          type: "invisible",
        },
        {
          name: "tags",
          displayname: "Tag",
          type: "invisible",
        },
        {
          name: "past",
          displayname: "Past",
          type: "object-tab",
        },
        {
          name: "upcoming",
          displayname: "Upcoming",
          type: "invisible",
        },
      ];
    } else if (type === "upcoming") {
      return [
        {
          name: "name",
          displayname: "Name",
          type: "invisible",
        },
        {
          name: "description",
          displayname: "Description",
          type: "invisible",
        },
        {
          name: "website",
          displayname: "Website",
          type: "invisible",
        },
        {
          name: "tags",
          displayname: "Tag",
          type: "invisible",
        },
        {
          name: "past",
          displayname: "Past",
          type: "invisible",
        },
        {
          name: "upcoming",
          displayname: "Upcoming",
          type: "object-tab",
        },
      ];
    }
  }

  function OpenedConference({ indexConf }) {
    useEffect(() => {
      setWantToEdit(false);
      setWantToDelete(false);
    });

    return (
      <>
        <DrawerHeader />
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink to="/rewriting/intranet/edit-website">Edit website</NavLink>
          <NavLink onClick={() => setWantOpenConference(false)}>
            Conferences
          </NavLink>
          <Typography color="text.primary">
            {filteredData[indexConf].name}
          </Typography>
        </Breadcrumbs>
        {wantToEditTypeConf && (
          <>
            <EditEntry
              key={"editentry" + counter}
              name={name}
              entries={entriesType(conferenceType)}
              data={dataEdited}
              _id={id}
              indexConference={indexConf}
              indexTypeConference={indexTypeConference}
              type={conferenceType}
              onSuccess={fetchData}
            />
          </>
        )}
        {wantToDeleteTypeConf && (
          <>
            <DeleteEntry
              key={"deleteentry" + counter}
              name={name}
              entries={entries}
              data={dataEdited}
              _id={id}
              indexTypeConference={indexTypeConference}
              type={conferenceType}
              onSuccess={fetchData}
            />
          </>
        )}

        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px",
            width: "100%",
            borderRadius: "10px",
          }}
          variant="outlined"
        >
          <Typography variant="h6" component="h2">
            {filteredData[indexConf].name}
          </Typography>
          <div>
            <Accordion expanded={upcomingAccordionExpanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  setUpcomingAccordionExpanded(!upcomingAccordionExpanded);
                  setWantToEditTypeConf(false);
                  setWantToDeleteTypeConf(false);
                }}
              >
                <Typography>Upcoming</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  background: "#F4F4F4",
                }}
              >
                <AddEntry
                  name={name}
                  label={"upcoming " + name}
                  entries={entriesType("upcoming")}
                  data={filteredData}
                  _id={filteredData[indexConf]._id}
                  onSuccess={fetchData}
                />

                {JSON.parse(
                  filteredData[conferenceIndex].upcoming.length > 0
                ) ? (
                  JSON.parse(filteredData[conferenceIndex].upcoming).map(
                    (conf, index) => (
                      <Paper
                        key={conf.name}
                        sx={{
                          p: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                        variant="outlined"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography>{conf.name}</Typography>
                          <Link to={conf.url} target="_blank">
                            {conf.url}
                          </Link>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            endIcon={<ModeEditIcon />}
                            sx={{ width: "fit-content" }}
                            onClick={(evt) => {
                              evt.preventDefault();
                              setIndexTypeConference(index);
                              setWantToEditTypeConf(true);
                              setWantToDeleteTypeConf(false);
                              setCounter((prevCounter) => prevCounter + 1);
                              setDataEdited(editfilteredData[indexConf]);
                              setConferenceType("upcoming");
                            }}
                          >
                            Edit
                          </Button>
                          <Tooltip title="Delete">
                            <IconButton
                              style={{
                                background: "#ffcdcd",
                                width: "fit-content",
                              }}
                              onClick={(evt) => {
                                evt.preventDefault();
                                setWantToDeleteTypeConf(true);
                                setIndexTypeConference(index);
                                setWantToEditTypeConf(false);
                                setCounter((prevCounter) => prevCounter + 1);
                                setDataEdited(filteredData[indexConf]);
                                setConferenceType("upcoming");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Paper>
                    )
                  )
                ) : (
                  <Typography>No upcoming conferences</Typography>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={pastAccordionExpanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                onClick={() => {
                  setPastAccordionExpanded(!pastAccordionExpanded);
                  setWantToEditTypeConf(false);
                  setWantToDeleteTypeConf(false);
                }}
              >
                <Typography>Past</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  background: "#F4F4F4",
                }}
              >
                <AddEntry
                  name={name}
                  entries={entriesType("past")}
                  data={filteredData}
                  _id={filteredData[indexConf]._id}
                  onSuccess={fetchData}
                />
                {JSON.parse(filteredData[conferenceIndex].past.length > 0) ? (
                  JSON.parse(filteredData[conferenceIndex].past).map(
                    (conf, index) => (
                      <Paper
                        key={conf.name}
                        sx={{
                          p: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                        variant="outlined"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography>{conf.name}</Typography>
                          <Link to={conf.url} target="_blank">
                            {conf.url}
                          </Link>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            endIcon={<ModeEditIcon />}
                            sx={{ width: "fit-content" }}
                            onClick={(evt) => {
                              evt.preventDefault();
                              setIndexTypeConference(index);
                              setWantToEditTypeConf(true);
                              setWantToDeleteTypeConf(false);
                              setCounter((prevCounter) => prevCounter + 1);
                              setDataEdited(editfilteredData[indexConf]);
                              setConferenceType("past");
                            }}
                          >
                            Edit
                          </Button>
                          <Tooltip title="Delete">
                            <IconButton
                              style={{
                                background: "#ffcdcd",
                                width: "fit-content",
                              }}
                              onClick={(evt) => {
                                evt.preventDefault();
                                setIndexTypeConference(index);
                                setWantToDeleteTypeConf(true);
                                setWantToEditTypeConf(false);
                                setCounter((prevCounter) => prevCounter + 1);
                                setDataEdited(filteredData[indexConf]);
                                setConferenceType("past");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Paper>
                    )
                  )
                ) : (
                  <Typography>No past conferences</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        </Paper>
      </>
    );
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <IntranetNavBar entitled="Edit website > Conferences" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "100%",
          overflowX: "scroll",
        }}
      >
        {wantOpenConference ? (
          <OpenedConference indexConf={conferenceIndex} />
        ) : (
          <>
            <DrawerHeader />
            <Breadcrumbs aria-label="breadcrumb">
              <NavLink
                underline="none"
                color="black"
                to="/rewriting/intranet/edit-website"
              >
                Edit website
              </NavLink>
              <Typography color="text.primary">Conferences</Typography>
            </Breadcrumbs>
            {wantToEdit && (
              <>
                <EditEntry
                  name={name}
                  entries={entries}
                  key={"editentry" + counter}
                  data={dataEdited}
                  onSuccess={fetchData}
                />
              </>
            )}
            {wantToDelete && (
              <>
                <DeleteEntry
                  name={name}
                  entries={entries}
                  key={"deleteentry" + counter}
                  data={dataEdited}
                  onSuccess={fetchData}
                />
              </>
            )}

            {loading ? (
              <>
                Loading data...
                <LinearProgress />{" "}
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >


                  <AddEntry
                    name={name}
                    entries={entries}
                    onSuccess={fetchData}
                  />
                </Box>
                {filteredData.map((row, index) => (
                  <Paper
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                      transition: "all 0.2s ease-in-out",
                      justifyContent: "space-between",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                      "@media (max-width: 600px)": {
                        flexDirection: "column",
                      },

                      // cursor: "pointer",
                      // "&:hover": {
                      //   //scale
                      //   backgroundColor: "#f5f5f5",
                      // },
                    }}
                    variant="outlined"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: "10px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(evt) => {
                        if (evt.target === evt.currentTarget) {
                          setWantOpenConference(true);
                          setConferenceIndex(row.id - 1);
                          setId(row._id);
                          setWantToEditTypeConf(false);
                          setWantToDeleteTypeConf(false);
                        }
                      }}
                    >
                      <Typography variant="h6">{row.name}</Typography>
                      <Typography variant="body1">{row.description}</Typography>
                      <Link to={row.website} target="_blank">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Typography variant="body1">{row.website}</Typography>
                          <OpenInNewIcon fontSize="20" />
                        </Box>
                      </Link>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          {row.tags.map((tag, index) => (
                            <Chip
                              label={tag}
                              sx={{ background: "#2C3E50", color: "white" }}
                              key={index}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                        "@media (max-width: 600px)": {
                          alignItems: "flex-start",
                          flexDirection: "row",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "flex-end",
                          "@media (max-width: 600px)": {
                            alignItems: "flex-start",
                            flexDirection: "row",
                          },
                        }}
                      >
                        <Button
                          variant="contained"
                          endIcon={<OpenInFullIcon />}
                          sx={{ width: "fit-content" }}
                          onClick={(evt) => {
                            setWantOpenConference(true);
                            setConferenceIndex(row.id - 1);
                            setId(row._id);
                            setWantToEditTypeConf(false);
                            setWantToDeleteTypeConf(false);
                          }}
                        >
                          Open
                        </Button>
                        <Button
                          variant="contained"
                          endIcon={<ModeEditIcon />}
                          sx={{ width: "fit-content" }}
                          onClick={(evt) => {
                            evt.preventDefault();
                            setWantToEdit(true);
                            setWantToDelete(false);
                            setCounter((prevCounter) => prevCounter + 1);
                            setDataEdited(filteredData[row.id - 1]);
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                      <Tooltip title="Delete">
                        <IconButton
                          style={{ background: "#ffcdcd" }}
                          onClick={(evt) => {
                            evt.preventDefault();
                            setWantToDelete(true);
                            setWantToEdit(false);
                            setCounter((prevCounter) => prevCounter + 1);
                            setDataEdited(filteredData[row.id - 1]);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Paper>
                ))}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default EditConferencesPage;
