import {
  Box,
  Chip,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { FiExternalLink } from "react-icons/fi";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";

const MediumCard = (props) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "600px",
          p: 2,
          borderRadius: "20px",
          transition: "all 0.2s ease-in-out",
          boxShadow:
            "0px 3px 1px -2px rgba(224,224,224,1),0px 2px 2px 0px rgba(224,224,224,1),0px 1px 5px 0px rgba(224,224,224,1)",

          "@media (max-width: 650px)": {
            width: "100%",
          },
        }}
        elevation={2}
      >
        {props.name && (
          <Tooltip title={props.website} placement="top">
            <Link
              to={props.website}
              target="_blank"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textDecoration: hover ? "underline" : "none",
                color: "#006789",
                gap: "5px",
                width: "fit-content",
                cursor: "pointer",
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <Typography variant="h6">{props.name}</Typography>
              <FiExternalLink size={20} />
            </Link>
          </Tooltip>
        )}

        {props.entitled && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Typography variant="h6">{props.entitled}</Typography>
            <Tooltip title="Read the problem" placement="top">
              <IconButton
                variant="contained"
                sx={{ ml: 1 }}
                onClick={handleOpen}
              >
                <OpenInFullRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {props.description && (
          <Typography variant="body1">{props.description}</Typography>
        )}
        {props.tags && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    label={tag}
                    size="small"
                    sx={{ color: "#2C3E50" }}
                    key={index}
                  />
                ))}
              </Box>
              {props.status && (
                <Chip
                  label={props.status}
                  sx={{
                    background: "#2C3E50",
                    color: "white",
                    width: "fit-content",
                  }}
                  size="small"
                />
              )}
            </Box>
          </>
        )}
      </Paper>
      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              width: "700px",
              height: "95%",
              borderRadius: "20px",
              overflowY: "scroll",
              "@media (max-width: 800px)": {
                width: "95%",
                height: "95%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {props.entitled && (
                  <Typography variant="h4">{props.entitled}</Typography>
                )}

                {props.status && (
                  <Chip
                    label={props.status}
                    sx={{ background: "#2C3E50", color: "#fff" }}
                  />
                )}
              </Box>
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", right: "8px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {props.tags &&
              props.tags.map((tag, index) => (
                <Chip
                  label={tag}
                  sx={{ color: "#2C3E50" }}
                  size="small"
                  key={index}
                />
              ))}

            {props.text && (
              <Box
                className="content"
                dangerouslySetInnerHTML={{ __html: props.text }}
                sx={{
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  mt: 2,
                }}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default MediumCard;
