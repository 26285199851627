import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import AddButton from "./AddButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useEffect } from "react";
import API_URL from "../constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  border: "2px solid #grey",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  maxHeight: "95%",
  overflowY: "auto",

  "@media (max-width: 600px)": {
    width: "95%",
  },
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

const textFieldStyle = { color: "#f5f5f5", width: "100%" };

export default function AddEntry(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTags([]);
  };
  const [states, setStates] = useState(Array(props.entries.length).fill(""));
  const [redirect, setRedirect] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [tagsList, setTagsList] = useState([{ id: 0, tag: "" }]);
  const [conferenceName, setConferenceName] = useState("");
  const [conferenceURL, setConferenceURL] = useState("");
  const [status, setStatus] = useState("Unsolved");
  const [tempPassword, setTempPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateState = (index, value) => {
    setStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = value;
      return newStates;
    });
  };

  function RemoveTag(props) {
    return (
      <Tooltip title="Remove tag" placement="right">
        <Button
          key={props.index}
          variant="contained"
          color="error"
          onClick={() => removeTag(props.index)}
        >
          <RemoveCircleOutlineIcon />
        </Button>
      </Tooltip>
    );
  }

  function removeTag(id) {
    const index = tagsList.findIndex((tagObject) => tagObject.id === id);
    if (index !== -1) {
      const newTagsList = [...tagsList];
      newTagsList.splice(index, 1);
      newTagsList.forEach((tagObject, index) => {
        tagObject.id = index;
      });
      setTagsList(newTagsList);
      setRefresh(refresh + 1);
    }
  }

  function AddTag() {
    return (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          setTags([...tags, ""]);
          //defini un id unique pour chaque tag
          setTagsList([...tagsList, { id: tagsList.length, tag: "" }]);
          tagsList.push({ id: tagsList.length, tag: "" });
        }}
      >
        Add a tag
      </Button>
    );
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const getState = (index) => {
    return states[index];
  };

  async function createEntry(event) {
    event.preventDefault();
    setLoading(true);
    const data = new FormData();

    if (props.data) {
      let conf = [{ name: conferenceName, url: conferenceURL }];
      const type = props.entries.find(
        (entry) => entry.type === "object-tab"
      ).name;
      data.append("type", type);
      data.append("event", JSON.stringify(conf));
    } else {
      props.entries.forEach((entry, index) => {
        if (entry.name === "tags") {
          const tags = tagsList.map((tagObject) => tagObject.tag);
          data.append(entry.name, JSON.stringify(tags));
        } else if (entry.name === "past" || entry.name === "upcoming") {
          data.append(entry.name, JSON.stringify([]));
        } else if (entry.name === "status") {
          data.append(entry.name, status);
        } else if (entry.name === "password") {
          data.append(entry.name, "random");
        } else if (
          entry.name === "name" &&
          props.name === "text/introduction"
        ) {
          data.append(entry.name, "introduction");
        } else if (entry.name === "name" && props.name === "introduction") {
          data.append(entry.name, "introduction");
        } else if (entry.name === "name" && props.name === "mailing-list") {
          data.append(entry.name, "mailing-list");
        } else {
          getState(index) === ""
            ? data.append(entry.name, "" + props.data[entry.name])
            : data.append(entry.name, "" + getState(index));
        }
      });
    }
    const url =
      API_URL +
      (props.resources ? "resources/" : "") +
      (props.text ? "text/" : "") +
      props.name +
      (props.data ? "/" + props._id : "");
    
    const response = await fetch(url, {
      method: "POST",
      body: data,
    });
    const res = await response.json();
    if (res.password) {
      setTempPassword(res.password);
    }

    if (response.ok) {
      setRedirect(true);
      setOpenSnackbar(true);
      setLoading(false);
     props.onSuccess();
    }
  }

  if (redirect) {
    handleClose();
    setRedirect(false);
  }

  useEffect(() => {
    if (tempPassword !== "") {
      setIsModalOpen(true);
    }
  }, [tempPassword]);

  return (
    <div>
      <AddButton
        function={handleOpen}
        name={tempPassword !== "" ? "Show password" : "Add " +(props.name ==="user/random-password" ? "user" : props.name)}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {tempPassword !== "" ? (
              <>
                {isModalOpen && (
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      User has been created.
                      <HowToRegIcon sx={{ color: "green" }} />
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Here's the password. It must be changed quickly.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        p: 1,
                        background: "#F5F5F5",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(tempPassword);
                      }}
                    >
                      <Typography variant="body1" sx={{ background: "white" }}>
                        {tempPassword}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigator.clipboard.writeText(tempPassword);
                        }}
                      >
                        Copy
                      </Button>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                <IconButton
                  onClick={handleClose}
                  sx={{ position: "absolute", right: "8px", top: "8px" }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Adding : {props.name}
                </Typography>

                <form onSubmit={createEntry}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {props.entries.map((entry, index) =>
                      entry.type === "short-text" ? (
                        entry.name === "tags" ? (
                          <>
                            {tagsList.map((tag, tagIndex) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "20px",
                                  "@media (max-width: 600px)": {
                                    flexDirection: "column",
                                  },
                                }}
                                key={"box" + index + tagIndex}
                              >
                                <TextField
                                  id="filled-basic"
                                  label={"Tag " + (tagIndex + 1)}
                                  key={
                                    "tag" +
                                    tag.id +
                                    (index + tagIndex) +
                                    refresh
                                  }
                                  variant="outlined"
                                  required
                                  type="text"
                                  defaultValue={tag.tag}
                                  onChange={(ev) => {
                                    updateState(
                                      index,
                                      tagsList.map((t, i) =>
                                        i === tagIndex ? ev.target.value : t
                                      )
                                    );
                                    // Create a new copy of tagsList and update the state
                                    const newTagsList = [...tagsList];
                                    newTagsList.forEach((tagObject) => {
                                      if (tagObject.id === tag.id) {
                                        tagObject.tag = ev.target.value;
                                      }
                                    });
                                    setTagsList(newTagsList);
                                  }}
                                  style={textFieldStyle}
                                />
                                {tagsList.length > 1 && (
                                  <RemoveTag index={tag.id} />
                                )}
                              </Box>
                            ))}
                            <AddTag />
                          </>
                        ) : (
                          <>
                            <TextField
                              id="filled-basic"
                              label={entry.displayname}
                              variant="outlined"
                              key={"short-text" + index}
                              type="text"
                              required
                              onChange={(ev) =>
                                updateState(index, ev.target.value)
                              }
                            />
                          </>
                        )
                      ) : entry.type === "multiline" ? (
                        <TextField
                          id="filled-basic"
                          label={entry.displayname}
                          variant="outlined"
                          multiline
                          type="text"
                          rows={5}
                          key={"multiline" + index}
                          required
                          onChange={(ev) => updateState(index, ev.target.value)}
                        />
                      ) : entry.type === "editor" ? (
                        <ReactQuill
                          key={index}
                          theme={"snow"}
                          onChange={(value) => {
                            updateState(index, value);
                          }}
                          modules={modules}
                        />
                      ) : entry.type === "email" ? (
                        <>
                          <TextField
                            id="filled-basic"
                            label={entry.displayname}
                            variant="outlined"
                            key={"short-text" + index}
                            type="email"
                            required
                            onChange={(ev) =>
                              updateState(index, ev.target.value)
                            }
                          />
                        </>
                      ) : entry.type === "invisible" ? (
                        ""
                      ) : entry.type === "object-tab" ? (
                        <>
                          <TextField
                            id="filled-basic"
                            label={"Name"}
                            variant="outlined"
                            key={"short-text" + index}
                            type="text"
                            required
                            onChange={(ev) =>
                              setConferenceName(ev.target.value)
                            }
                          />
                          <TextField
                            id="filled-basic"
                            label={"URL"}
                            variant="outlined"
                            key={"short-text" + index + 1}
                            type="text"
                            required
                            onChange={(ev) => setConferenceURL(ev.target.value)}
                          />
                        </>
                      ) : entry.type === "status" ? (
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="simple-select-label">
                              Status
                            </InputLabel>
                            <Select
                              labelId="simple-select-label"
                              id="simple-select"
                              value={status}
                              label="Status"
                              onChange={(event) =>
                                setStatus(event.target.value)
                              }
                              defaultChecked={"Unsolved"}
                            >
                              <MenuItem value={"Unsolved"}>Unsolved</MenuItem>
                              <MenuItem value={"Solved"}>Solved</MenuItem>
                              <MenuItem value={"Open-ended"}>
                                Open-ended
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      ) : entry.type === "select" ? (
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="simple-select-label">
                              {entry.displayname}
                            </InputLabel>
                            <Select
                              labelId="simple-select-label"
                              id="simple-select"
                              label={entry.displayname}
                              onChange={(ev) =>
                                updateState(index, ev.target.value)
                              }
                              value={getState(index)}
                              defaultChecked={"Unsolved"}
                              required
                            >
                              {entry.fields.map((field, indexField) => (
                                <MenuItem key={indexField} value={field}>
                                  {field}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        (entry.type = "random-password" ? (
                          ""
                        ) : (
                          <TextField
                            id="filled-basic"
                            label={entry.displayname}
                            variant="outlined"
                            value={props.data[entry.name]}
                            type="text"
                            key={index}
                            defaultValue={props.data[entry.name]}
                            onChange={(ev) =>
                              updateState(index, ev.target.value)
                            }
                          />
                        ))
                      )
                    )}

                    <Button variant="contained" color="success" type="submit">
                      Add
                    </Button>
                  </Box>
                  {loading && <LinearProgress />}
                </form>
              </>
            )}
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Entry added successfully ! Refresh the page to see the changes.
        </Alert>
      </Snackbar>
    </div>
  );
}
