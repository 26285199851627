import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import API_URL from "../constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  border: "2px solid #grey",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  maxHeight: "95%",
  overflowY: "auto",
  overflowX: "auto",

  "@media (max-width: 600px)": {
    maxWidth: "95%",
  },
};

const textFieldStyle = { color: "#f5f5f5", width: "100%" };

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

export default function EditEntry(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [states, setStates] = useState(Array(props.entries.length).fill(""));
  const [redirect, setRedirect] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [tags, setTags] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [tagsList, setTagsList] = useState([]);
  const [conferenceName, setConferenceName] = useState("");
  const [conferenceURL, setConferenceURL] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    handleOpen();
    if (props.data["tags"]) {
      const newTagsList = [];
      for (let i = 0; i < props.data["tags"].length; i++) {
        newTagsList.push({ id: i, tag: props.data["tags"][i], new: false });
      }
      setTagsList(newTagsList);
    }

    if (props.type) {
      conferenceURL === "" &&
        setConferenceURL(props.data[props.type][props.indexTypeConference].url);
      conferenceName === "" &&
        setConferenceName(
          props.data[props.type][props.indexTypeConference].name
        );
    }
    if (props.data["status"]) {
      setStatus(props.data["status"]);
    }
  }, [
    props.data,
    props.type,
    props.indexTypeConference,
    conferenceURL,
    conferenceName,
  ]);

  function RemoveTag(props) {
    return (
      <Tooltip title="Remove tag" placement="right">
        <Button
          variant="contained"
          color="error"
          onClick={() => removeTag(props.index)}
        >
          <RemoveCircleOutlineIcon />
        </Button>
      </Tooltip>
    );
  }

  function removeTag(id) {
    const index = tagsList.findIndex((tagObject) => tagObject.id === id);
    if (index !== -1) {
      const newTagsList = [...tagsList];
      newTagsList.splice(index, 1);
      newTagsList.forEach((tagObject, index) => {
        tagObject.id = index;
      });
      setTagsList(newTagsList);
      setRefresh(refresh + 1);
    }
  }

  function AddTag() {
    return props.entries.map(
      (entry, index) =>
        entry.name === "tags" && (
          <Button
            key={index}
            variant="contained"
            color="success"
            onClick={() => {
              setTags([...tags, ""]);
              tagsList.push({ id: tagsList.length, tag: "", new: true });
            }}
          >
            Add a tag
          </Button>
        )
    );
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const updateState = (index, value) => {
    setStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = value;
      return newStates;
    });
  };

  const getState = (index) => {
    return states[index];
  };

  async function editEntry(event) {
    event.preventDefault();
    setLoading(true);
    const data = new FormData();
    if (props.type) {
      data.append("id", props.data._id);
      data.append(
        "idType",
        JSON.stringify(props.data[props.type][props.indexTypeConference]._id)
      );
      data.append("indexType", props.indexTypeConference);
      data.append("type", props.type);
      data.append("changes", JSON.stringify([conferenceName, conferenceURL]));
    } else {
      props.entries.forEach((entry, index) => {
        if (entry.name === "tags") {
          const tags = tagsList.map((tagObject) => tagObject.tag);
          data.append(entry.name, JSON.stringify(tags));
        } else if (
          (entry.name === "past" && props.type === "past") ||
          (entry.name === "upcoming" && props.type === "upcoming")
        ) {
          data.append(
            entry.name,
            JSON.stringify([conferenceName, conferenceURL])
          );
        } else if (entry.name === "status") {
          data.append(entry.name, status);
        } else {
          if (getState(index) === "") {
            data.append(entry.name, "" + props.data[entry.name]);
          } else {
            data.append(entry.name, "" + getState(index));
          }
        }
      });
    }

    editorContent !== "" && data.set("content", editorContent);

    const response = await fetch(
      API_URL +
        (props.resources ? "resources/" : "") +
        (props.text ? "text/" : "") +
        props.name +
        "/" +
        (!props.type ? props.data._id : "") +
        (props.type ? "edit-type" : ""),
      {
        method: "PUT",
        body: data,
      }
    );

    if (response.ok) {
      setRedirect(true);
      setOpenSnackbar(true);
      setLoading(false);
      props.onSuccess(); // Appel à onSuccess pour rafraîchir les données
    }
  }

  if (redirect) {
    handleClose();
    setRedirect(false);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", right: "8px", top: "8px" }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Edit {props.name}
            </Typography>

            <form onSubmit={editEntry}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {props.entries.map((entry, index) =>
                  entry.type === "short-text" ? (
                    entry.name === "tags" ? (
                      <>
                        {tagsList.map((tag, tagIndex) =>
                          tag.new === false ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                                "@media (max-width: 600px)": {
                                  flexDirection: "column",
                                },
                              }}
                              key={"box" + index + tagIndex + refresh}
                            >
                              <TextField
                                id="filled-basic"
                                label={"Previous Tag " + (tagIndex + 1)}
                                defaultValue={tag.tag}
                                variant="outlined"
                                key={"disabled" + 2 * refresh}
                                disabled
                                type="text"
                                sx={{
                                  backgroundColor: "#f5f5f5",
                                  width: "100%",
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "20px",
                                  width: "100%",
                                }}
                              >
                                <TextField
                                  id="filled-basic"
                                  label={"Tag " + (tagIndex + 1)}
                                  key={"tag" + tag.id + refresh}
                                  variant="outlined"
                                  type="text"
                                  required
                                  defaultValue={tag.tag}
                                  onChange={(ev) => {
                                    updateState(
                                      index,
                                      props.data[entry.name].map((t, i) =>
                                        i === tagIndex ? ev.target.value : t
                                      )
                                    );
                                    // Create a new copy of tagsList and update the state
                                    const newTagsList = [...tagsList];
                                    newTagsList.forEach((tagObject) => {
                                      if (tagObject.id === tag.id) {
                                        tagObject.tag = ev.target.value;
                                      }
                                    });
                                    setTagsList(newTagsList);
                                  }}
                                  style={textFieldStyle}
                                />
                                {tagsList.length > 1 && (
                                  <RemoveTag index={tag.id} />
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                                width: "100%",
                              }}
                              key={"box" + index + tagIndex}
                            >
                              <TextField
                                id="filled-basic"
                                label={"Tag " + (tagIndex + 1)}
                                key={tag.id + refresh}
                                variant="outlined"
                                type="text"
                                required
                                defaultValue={tag.tag}
                                onChange={(ev) => {
                                  updateState(
                                    index,
                                    props.data[entry.name].map((t, i) =>
                                      i === tagIndex ? ev.target.value : t
                                    )
                                  );
                                  // Create a new copy of tagsList and update the state
                                  const newTagsList = [...tagsList];
                                  newTagsList.forEach((tagObject) => {
                                    if (tagObject.id === tag.id) {
                                      tagObject.tag = ev.target.value;
                                    }
                                  });
                                  setTagsList(newTagsList);
                                }}
                                style={textFieldStyle}
                              />
                              {tagsList.length > 1 && (
                                <RemoveTag index={tag.id} />
                              )}
                            </Box>
                          )
                        )}
                        <AddTag />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                        }}
                        key={"box" + index}
                      >
                        <TextField
                          id="filled-basic"
                          label={"Previous " + entry.displayname}
                          value={props.data[entry.name]}
                          variant="outlined"
                          key={"texfielddisabled" + index}
                          disabled
                          type="text"
                          onChange={(ev) => updateState(index, ev.target.value)}
                          style={{ backgroundColor: "#f5f5f5", width: "100%" }}
                        />
                        <TextField
                          id="filled-basic"
                          label={"New " + entry.displayname}
                          variant="outlined"
                          key={index}
                          type="text"
                          defaultValue={props.data[entry.name]}
                          onChange={(ev) => updateState(index, ev.target.value)}
                          style={textFieldStyle}
                        />
                      </Box>
                    )
                  ) : entry.type === "multiline" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                      key={"box" + index}
                    >
                      <TextField
                        id="filled-basic"
                        label={entry.displayname}
                        variant="outlined"
                        value={props.data[entry.name]}
                        multiline
                        type="text"
                        rows={5}
                        key={"multilinedisabled" + index}
                        disabled
                        onChange={(ev) => updateState(index, ev.target.value)}
                        style={{ backgroundColor: "#f5f5f5" }}
                      />
                      <TextField
                        id="filled-basic"
                        label={entry.displayname}
                        variant="outlined"
                        multiline
                        type="text"
                        rows={5}
                        key={index}
                        defaultValue={props.data[entry.name]}
                        onChange={(ev) => updateState(index, ev.target.value)}
                      />
                    </Box>
                  ) : entry.type === "editor" ? (
                    <ReactQuill
                      key={index}
                      theme={"snow"}
                      onChange={(value) => {
                        setEditorContent(value);
                        updateState(index, value);
                      }}
                      modules={modules}
                      defaultValue={
                        props.data.content
                          ? props.data.content
                          : props.data[entry.name]
                          ? props.data[entry.name]
                          : ""
                      }
                    />
                  ) : entry.type === "invisible" ? (
                    ""
                  ) : entry.type === "email" ? (
                    <>
                      <TextField
                        id="filled-basic"
                        label={entry.displayname}
                        variant="outlined"
                        key={"short-text" + index}
                        type="email"
                        required
                        defaultValue={
                          props.data.content
                            ? props.data.content
                            : props.data[entry.name]
                            ? props.data[entry.name]
                            : ""
                        }
                        onChange={(ev) => updateState(index, ev.target.value)}
                      />
                    </>
                  ) : entry.type === "object-tab" ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                          width: "100%",
                        }}
                        key={"box" + index}
                      >
                        <TextField
                          id="filled-basic"
                          label={"Previous name"}
                          variant="outlined"
                          key={
                            "short-text" +
                            index +
                            props.data[entry.name][props.indexTypeConference]
                              .name
                          }
                          type="text"
                          required
                          defaultValue={
                            props.data[entry.name][props.indexTypeConference]
                              .name
                          }
                          sx={{
                            backgroundColor: "#f5f5f5",
                            width: "100%",
                          }}
                          disabled
                        />
                        <TextField
                          id="filled-basic"
                          label={"Name"}
                          variant="outlined"
                          key={"short-text" + index + 1}
                          type="text"
                          required
                          defaultValue={
                            props.data[entry.name][props.indexTypeConference]
                              .name
                          }
                          onChange={(ev) => setConferenceName(ev.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                          width: "100%",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                        }}
                        key={"box" + (index + 1)}
                      >
                        <TextField
                          id="filled-basic"
                          label={"Previous URL"}
                          variant="outlined"
                          key={
                            "short-text" +
                            index +
                            props.data[entry.name][props.indexTypeConference]
                              .url
                          }
                          type="text"
                          required
                          defaultValue={
                            props.data[entry.name][props.indexTypeConference]
                              .url
                          }
                          sx={{
                            backgroundColor: "#f5f5f5",
                            width: "100%",
                          }}
                          disabled
                        />
                        <TextField
                          id="filled-basic"
                          label={"URL"}
                          variant="outlined"
                          key={"short-text" + index + 1}
                          type="text"
                          required
                          defaultValue={
                            props.data[entry.name][props.indexTypeConference]
                              .url
                          }
                          onChange={(ev) => setConferenceURL(ev.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </Box>
                    </>
                  ) : entry.type === "status" ? (
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label">Status</InputLabel>
                        <Select
                          labelId="simple-select-label"
                          id="simple-select"
                          value={status}
                          label="Status"
                          onChange={(event) => setStatus(event.target.value)}
                          defaultChecked={"Unsolved"}
                        >
                          <MenuItem value={"Unsolved"}>Unsolved</MenuItem>
                          <MenuItem value={"Solved"}>Solved</MenuItem>
                          <MenuItem value={"Open-ended"}>Open-ended</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : entry.type === "select" ? (
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label">
                          {entry.displayname}
                        </InputLabel>
                        <Select
                          labelId="simple-select-label"
                          id="simple-select"
                          label={entry.displayname}
                          defaultValue={props.data[entry.name]}
                          onChange={(ev) => updateState(index, ev.target.value)}
                          defaultChecked={"Unsolved"}
                          required
                        >
                          {entry.fields.map((field, indexField) => (
                            <MenuItem key={indexField} value={field}>
                              {field}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <TextField
                      id="filled-basic"
                      label={entry.displayname}
                      variant="outlined"
                      value={props.data[entry.name]}
                      type="text"
                      key={index}
                      defaultValue={props.data[entry.name]}
                      onChange={(ev) => updateState(index, ev.target.value)}
                    />
                  )
                )}
                <Button variant="contained" type="submit">
                  Edit
                </Button>
              </Box>
              {loading && <LinearProgress />}
            </form>
          </Box>
        </Fade>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Entry edited successfully !
        </Alert>
      </Snackbar>
    </div>
  );
}
