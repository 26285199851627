
// set by default base api url as production url
const API_URL =  "https://rewriting.inria.fr/api/";


// // update the base api url in the mode is development
// if (process.env.NODE_ENV === 'development') {
    // const API_URL = "http://localhost:5000/api/"
// }

export default API_URL;