import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function DataTable(props) {
  const columns = props.columns;
  const rows = props.rows;

  return (
    <div style={{ maxWidth: "100%", overflowX: "auto" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        onCellClick={(params, event) => {
          if (params.field === "edit" || params.field === "delete") {
            event.stopPropagation();
          }
        }}
      />
    </div>
  );
}
