import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ButtonPage = (props) => {
  const { name, url, color } = props;
  const [textColor, setTextColor] = useState(color);
  const [bg, setBg] = useState("rgb(250, 250, 250)");
  const textColorIntranet =
    props.name === "Intranet" ? "color: black" : textColor;

  const handleMouseEnter = () => {
    setBg(color);
    setTextColor("white");
  };
  const handleMouseLeave = () => {
    setBg("rgb(250, 250, 250)");
    setTextColor(color);
  };

  return (
    <NavLink
      to={url}
      className={"button-page " + name.toLowerCase()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        border: "3px solid " + color,
        background: bg,
        color: textColorIntranet,
      }}
    >
      {name}
      {props.name !== "Intranet" && (
        <div className="arrow">
          <KeyboardArrowRightIcon style={{ width: "30px", height: "30px" }} />
        </div>
      )}
    </NavLink>
  );
};

export default ButtonPage;
