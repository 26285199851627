import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FiExternalLink } from "react-icons/fi";
import ListItemIcon from "@mui/material/ListItemIcon";
import Lock from "@mui/icons-material/Lock";

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #303030;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  transition: 0.25s;
  &:hover {
    background: #e6e6e6;
  }
`;

const NavBarPath = [
  {
    name: "Introduction",
    path: "/rewriting/introduction",
  },
  {
    name: "Open problems",
    path: "/rewriting/open-problems",
  },
  {
    name: "Researchers",
    path: "/rewriting/researchers",
  },
  {
    name: "Conferences",
    path: "/rewriting/conferences",
  },
  {
    name: "Workshops",
    path: "/rewriting/workshops",
  },
  {
    name: "Tools",
    path: "/rewriting/tools",
  },
  {
    name: "Companies",
    path: "/rewriting/companies",
  },
  {
    name: "Competitions",
    path: "/rewriting/competitions",
  },
  // {
  //   name: "Mailing lists",
  //   path: "/rewriting/mailing-lists",
  // },
  {
    name: "Resources",
    path: "/rewriting/resources",
  },
  {
    name: "IFIP WG 1.6",
    path: "https://ifip-wg-rewriting.cs.ru.nl/",
    icon: <FiExternalLink size={15} />,
  },
  {
    name: "EuroProofNet",
    path: "https://europroofnet.github.io",
    icon: <FiExternalLink size={15} />,
  },
];

const drawerWidth = 240;

function Navigation(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {NavBarPath.map((item, index) =>
          item.icon ? (
            <NavLink
              to={item.path}
              key={index}
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    textAlign: "center",
                    color: "#303030",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemText primary={item.name} />
                  <ListItemIcon>{item.icon}</ListItemIcon>
                </ListItemButton>
              </ListItem>
            </NavLink>
          ) : props.name === item.name ? (
            <NavLink
              to={item.path}
              key={index}
              style={{
                textDecoration: "none",
                background: "#2C3E50",
                color: "#fff",
              }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    textAlign: "center",
                    background: "#2C3E50",
                    color: "#fff",
                    "&:hover": {
                      background: "#2C3E50",
                    },
                  }}
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ) : (
            <NavLink
              to={item.path}
              key={index}
              style={{ textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: "center", color: "#303030" }}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          )
        )}
        <NavLink to="/rewriting/login" style={{ textDecoration: "none" }}>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#303030",
              }}
            >
              <Lock />
            </ListItemButton>
          </ListItem>
        </NavLink>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          background: "rgb(250, 250, 250)",
          borderBottom: "1px solid #e6e6e6",
          //remove boxshadow
          boxShadow: "none",
          // boxShadow:
          //   "0px 3px 1px -2px rgba(224,224,224,1),0px 2px 2px 0px rgba(224,224,224,1),0px 1px 5px 0px rgba(224,224,224,1)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xl: "none" }, color: "#2C3E50" }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", xl: "flex" },
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {NavBarPath.map((item, index) =>
              item.icon ? (
                <StyledNavLink to={item.path} key={index} target="_blank">
                  {item.name} {item.icon}
                </StyledNavLink>
              ) : props.name === item.name ? (
                <StyledNavLink
                  to={item.path}
                  key={index}
                  style={{
                    background: "#2C3E50",
                    color: "#fff",
                    hover: { background: "#2C3E50" },
                  }}
                >
                  {item.name}
                </StyledNavLink>
              ) : (
                <StyledNavLink to={item.path} key={index}>
                  {item.name}
                </StyledNavLink>
              )
            )}
            <StyledNavLink
              to="/rewriting/login"
              style={{ borderRadius: "100px" }}
            >
              <Lock />
            </StyledNavLink>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", xl: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Navigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navigation;
