import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ArchiveIcon from "@mui/icons-material/Archive"; // Importer l'icône Archive
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const IntranetNavBar = ({ entitled }) => {
  const { setUserInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("authenticated");
    setUserInfo(null);
    navigate("/rewriting/login");
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={{ bgcolor: "#2C3E50" }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginLeft: 10 }}>
            {entitled}
          </Typography>
          <div>
            <IconButton
              aria-label="edit website"
              onClick={() => navigate("/rewriting/intranet/edit-website")}
              color="inherit"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="archive"
              onClick={() => navigate("/rewriting/intranet/archive")}
              color="inherit"
            >
              <ArchiveIcon /> {/* Ajouter l'icône Archive */}
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <PersonOutlineIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {handleClose(); navigate("/rewriting/intranet/my-account");}}>My Account</MenuItem>
              <MenuItem onClick={() => {handleClose(); navigate("/rewriting/intranet/manage-accounts");}}>Manage Accounts</MenuItem>
              <MenuItem onClick={() => {handleClose(); handleLogout();}}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default IntranetNavBar;
