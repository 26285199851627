import React, { useEffect } from "react";
import Title from "../../components/Title";
import Card from "../../components/Card";
import Path from "../../components/Path";
import Footer from "../../components/Footer";

const TempIndustrialToolsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const links = [
    {
      title: "Industrial tool n°1",
      url: "",
      tags: ["Rewriting", "Logic"],
    },
    {
      title: "Industrial tool n°2",
      url: "",
      tags: ["Rewriting", "Lambda Calculus"],
    },
    {
      title: "Industrial tool n°3",
      url: "",
      tags: ["Lambda Calculus"],
    },
    {
      title: "Industrial tool n°4",
      url: "",
      tags: ["Logic"],
    },
    {
      title: "Industrial tool n°5",
      url: "",
      tags: ["Rewriting"],
    },
    {
      title: "Industrial tool n°6",
      url: "",
      tags: ["Rewriting"],
    },
    {
      title: "Industrial tool n°7",
      url: "",
      tags: ["Rewriting", "Logic", "Lambda Calculus"],
    },
    {
      title: "Industrial tool n°8",
      url: "",
      tags: ["Logic"],
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Industrial tools",
      path: "/rewriting/temp/industrial-tools",
    },
  ];

  return (
    <div className="container">
      <div className="main no-nav-bar">
        <Path paths={paths} />
        <Title title="Industrial tools" />

        <div className="industrial-applications-cards-container">
          {links.map(({ title, url, tags }) => (
            <Card title={title} url={url} tags={tags} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TempIndustrialToolsPage;
