import React from "react";
import { NavLink } from "react-router-dom";

const Path = (props) => {
  return (
    <ul className={"path-container " + props.branch}>
      {props.paths.map((element, index) => (
        <li key={index + 1}>
          <NavLink to={element.path} className={element.name}>
            {element.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Path;
