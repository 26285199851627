import React from "react";
import { Routes, Route } from "react-router-dom";
import SplashPage from "./pages/temp/SplashPage";
import SplashPageNavigation from "./components/SplashPageNavigation";
import TempOpenProblemsPage from "./pages/temp/TempOpenProblemsPage";
import TempResearchersPage from "./pages/temp/TempResearchersPage";
import TempIndustrialTools from "./pages/temp/TempIndustrialToolsPage";
import TempConferencesPage from "./pages/temp/TempConferencesPage";
import TempCompaniesPage from "./pages/temp/TempCompaniesPage";
import TempFreeToolsPage from "./pages/temp/TempFreeToolsPage";
import TempLanguagesPage from "./pages/temp/TempLangagesPage";
import LoginPageRef from "./pages/LoginPageRef";
import ConfirmAccountPage from "./pages/ConfirmAccountPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetPasswordPage2 from "./pages/ResetPasswordPage2";
//Term Rewriting pages
import TermRewritingIntroductionPage from "./pages/temp/term-rewriting/TermRewritingIntroductionPage";
import TermRewritingRessourcesPage from "./pages/temp/term-rewriting/TermRewritingRessourcesPage";
import TermRewritingLearningRessourcesPage from "./pages/temp/term-rewriting/ressources/TermRewritingLearningRessourcesPage";
import TermRewritingExternalRessourcesPage from "./pages/temp/term-rewriting/ressources/TermRewritingExternalRessourcesPage";
import TermRewritingBooksPage from "./pages/temp/term-rewriting/ressources/learningressources/TermRewritingBooksPage";
import TermRewritingSurveysPage from "./pages/temp/term-rewriting/ressources/learningressources/TermRewritingSurveysPage";
import TermRewritingCoursesPage from "./pages/temp/term-rewriting/ressources/learningressources/TermRewritingCoursesPage";

//Logic pages
import LogicIntroductionPage from "./pages/temp/logic/LogicIntroductionPage";
import LogicRessourcesPage from "./pages/temp/logic/LogicRessourcesPage";
import LogicLearningRessourcesPage from "./pages/temp/logic/ressources/LogicLearningRessourcesPage";
import LogicExternalRessourcesPage from "./pages/temp/logic/ressources/LogicExternalRessourcesPage";
import LogicBooksPage from "./pages/temp/logic/ressources/learningressources/LogicBooksPage";
import LogicSurveysPage from "./pages/temp/logic/ressources/learningressources/LogicSurveysPage";
import LogicCoursesPage from "./pages/temp/logic/ressources/learningressources/LogicCoursesPage";

//LambdaCalculus pages
import LambdaCalculusMainPage from "./pages/temp/lambda-calculus/LambdaCalculusMainPage";
import LambdaCalculusRessourcesPage from "./pages/temp/lambda-calculus/LambdaCalculusRessourcesPage";
import LambdaCalculusLearningRessourcesPage from "./pages/temp/lambda-calculus/ressources/LambdaCalculusLearningRessourcesPage";
import LambdaCalculusBooksPage from "./pages/temp/lambda-calculus/ressources/learningressources/LambdaCalculusBooksPage";
import LambdaCalculusSurveysPage from "./pages/temp/lambda-calculus/ressources/learningressources/LambdaCalculusSurveysPage";
import LambdaCalculusCourses from "./pages/temp/lambda-calculus/ressources/learningressources/LambdaCalculusCoursesPage";
import LambdaCalculusExternalRessourcesPage from "./pages/temp/lambda-calculus/ressources/LambdaCalculusExternalRessourcesPage";

//Intranet pages
import MyAccountPage from "./pages/intranet/MyAccountPage";
import EditWebsitePage from "./pages/intranet/EditWebsitePage";
import ManageAccountsPage from "./pages/intranet/ManageAccountsPage";
import Archive from "./pages/intranet/Archive";

//Components
import IntroductionPage from "./pages/rewriting/IntroductionPage";
import ResourcesPage from "./pages/rewriting/ResourcesPage";
import BooksPage from "./pages/rewriting/ressources/BooksPage";
import OthersPage from "./pages/rewriting/ressources/OthersPage";
import CoursesPage from "./pages/rewriting/ressources/CoursesPage";
import ToolsPage from "./pages/rewriting/ToolsPage";
import ConferencesPage from "./pages/rewriting/ConferencesPage";
import IndustrialToolsPage from "./pages/rewriting/tools/IndustrialToolsPage";
import FreeToolsPage from "./pages/rewriting/tools/FreeToolsPage";
import CompaniesPage from "./pages/rewriting/CompaniesPage";
import OpenProblemsPage from "./pages/rewriting/OpenProblemsPage";
import ResearchersPage from "./pages/rewriting/ResearchersPage";
import CompetitionsPage from "./pages/rewriting/CompetitionsPage";
import WorkshopsPage from "./pages/rewriting/WorkshopsPage";
import { UserContextProvider } from "./UserContext";
import EditResearchersPage from "./pages/intranet/edit-website/EditResearchersPage";
import EditCompaniesPage from "./pages/intranet/edit-website/EditCompaniesPage";
import EditIntroductionPage from "./pages/intranet/edit-website/EditIntroductionPage";
// import EditMailingListPage from "./pages/intranet/edit-website/EditMailingListPage";
import EditConferencesPage from "./pages/intranet/edit-website/EditConferencesPage";
import EditResourcesPage from "./pages/intranet/edit-website/EditResourcesPage";
import EditBooksPage from "./pages/intranet/edit-website/ressources/EditBooksPage";
import EditCoursesPage from "./pages/intranet/edit-website/ressources/EditCoursesPage";
import EditOthersPage from "./pages/intranet/edit-website/ressources/EditOthersPage";
import EditUserInfo from "./components/EditUserInfo";
import EditWorkshopPage from "./pages/intranet/edit-website/EditWorkshopPage";
import EditToolsPage from "./pages/intranet/edit-website/EditToolsPage";
import EditOpenProblemsPage from "./pages/intranet/edit-website/EditOpenProblemsPage";
import EditCompetitionsPage from "./pages/intranet/edit-website/EditCompetitionsPage";
import RegisterPage from "./pages/RegisterPage";

const App = () => {
    return (<
        UserContextProvider >
        <
            Routes >
            <
                Route path="/rewriting/temp"
                element={< SplashPage />}
            /> {/* Term Rewriting Routes */} <
                Route path="/rewriting/temp/term-rewriting/introduction"
                element={< TermRewritingIntroductionPage />}
            /> <
                Route path="/rewriting/temp/term-rewriting/ressources"
                element={< TermRewritingRessourcesPage />}
            /> <
                Route path="/rewriting/temp/term-rewriting/ressources/learning-ressources"
                element={< TermRewritingLearningRessourcesPage />}
            /> <
                Route path="/rewriting/temp/term-rewriting/ressources/learning-ressources/books"
                element={< TermRewritingBooksPage />}
            /> <
                Route path="/rewriting/temp/term-rewriting/ressources/learning-ressources/surveys"
                element={< TermRewritingSurveysPage />}
            /> <
                Route path="/rewriting/temp/term-rewriting/ressources/learning-ressources/courses"
                element={< TermRewritingCoursesPage />}
            /> <
                Route path="/rewriting/temp/term-rewriting/ressources/external-ressources"
                element={< TermRewritingExternalRessourcesPage />}
            /> {/* Lambda Calculus Routes */} <
                Route path="/rewriting/temp/lambda-calculus/introduction"
                element={< LambdaCalculusMainPage />}
            /> <
                Route path="/rewriting/temp/lambda-calculus/ressources"
                element={< LambdaCalculusRessourcesPage />}
            /> <
                Route path="/rewriting/temp/lambda-calculus/ressources/learning-ressources"
                element={< LambdaCalculusLearningRessourcesPage />}
            /> <
                Route path="/rewriting/temp/lambda-calculus/ressources/learning-ressources/books"
                element={< LambdaCalculusBooksPage />}
            /> <
                Route path="/rewriting/temp/lambda-calculus/ressources/learning-ressources/surveys"
                element={< LambdaCalculusSurveysPage />}
            /> <
                Route path="/rewriting/temp/lambda-calculus/ressources/learning-ressources/courses"
                element={< LambdaCalculusCourses />}
            /> <
                Route path="/rewriting/temp/lambda-calculus/ressources/external-ressources"
                element={< LambdaCalculusExternalRessourcesPage />}
            /> {/* Logic Routes */} <
                Route path="/rewriting/temp/logic-and-computation/introduction"
                element={< LogicIntroductionPage />}
            /> <
                Route path="/rewriting/temp/logic-and-computation/ressources"
                element={< LogicRessourcesPage />}
            /> <
                Route path="/rewriting/temp/logic-and-computation/ressources/learning-ressources"
                element={< LogicLearningRessourcesPage />}
            /> <
                Route path="/rewriting/temp/logic-and-computation/ressources/learning-ressources/books"
                element={< LogicBooksPage />}
            /> <
                Route path="/rewriting/temp/logic-and-computation/ressources/learning-ressources/surveys"
                element={< LogicSurveysPage />}
            /> <
                Route path="/rewriting/temp/logic-and-computation/ressources/learning-ressources/courses"
                element={< LogicCoursesPage />}
            /> <
                Route path="/rewriting/temp/logic-and-computation/ressources/external-ressources"
                element={< LogicExternalRessourcesPage />}
            /> {/* Temp */} <
                Route path="/rewriting/*"
                element={< IntroductionPage />}
            /> <
                Route path="rewriting/resources"
                element={< ResourcesPage />}
            />

            <
                Route path="rewriting/resources/books"
                element={< BooksPage />}
            />

            <
                Route path="rewriting/resources/others"
                element={< OthersPage />}
            />

            <
                Route path="rewriting/resources/courses"
                element={< CoursesPage />}
            />

            <
                Route path="/rewriting/open-problems"
                element={< OpenProblemsPage />}
            /> <
                Route path="/rewriting/companies"
                element={< CompaniesPage />}
            /> <
                Route path="/rewriting/researchers"
                element={< ResearchersPage />}
            /> <
                Route path="/rewriting/conferences"
                element={< ConferencesPage />}
            />



            <
                Route path="/rewriting/tools"
                element={< ToolsPage />}
            /> <
                Route path="/rewriting/tools/free-tools"
                element={< FreeToolsPage />}
            /> <
                Route path="/rewriting/tools/industrial-tools"
                element={< IndustrialToolsPage />}
            /> <
                Route path="/rewriting/competitions"
                element={< CompetitionsPage />}
            /> <
                Route path="/rewriting/workshops"
                element={< WorkshopsPage />}
            />

            { /* Other Routes */}

            <
                Route path="/rewriting/temp/open-problems"
                element={< TempOpenProblemsPage />}
            /> <
                Route path="/rewriting/temp/researchers"
                element={< TempResearchersPage />}
            /> <
                Route path="/rewriting/temp/conferences"
                element={< TempConferencesPage />}
            /> <
                Route path="/rewriting/temp/industrial-tools"
                element={< TempIndustrialTools />}
            /> <
                Route path="/rewriting/temp/companies"
                element={< TempCompaniesPage />}
            /> <
                Route path="/rewriting/temp/free-tools"
                element={< TempFreeToolsPage />}
            /> <
                Route path="/rewriting/temp/languages"
                element={< TempLanguagesPage />}
            />

            { /* Intranet routes */}

            <
                Route path="/rewriting/intranet/my-account"
                element={< MyAccountPage />}
            />

            <
                Route path="/rewriting/intranet/edit-password"
                element={< EditUserInfo entitled="password" />}
            />

            <
                Route path="/rewriting/intranet/edit-email"
                element={< EditUserInfo entitled="email" />}
            />

            <
                Route path="/rewriting/intranet/edit-website"
                element={< EditWebsitePage />}
            /> <
                Route path="/rewriting/intranet/edit-website/researchers"
                element={< EditResearchersPage />}
            /> <
                Route path="/rewriting/intranet/edit-website/companies"
                element={< EditCompaniesPage />}
            /> <
                Route path="/rewriting/intranet/edit-website/introduction"
                element={< EditIntroductionPage />}
            />


            <
                Route path="/rewriting/register"
                element={< RegisterPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/resources"
                element={< EditResourcesPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/resources/books"
                element={< EditBooksPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/resources/courses"
                element={< EditCoursesPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/resources/others"
                element={< EditOthersPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/conferences"
                element={< EditConferencesPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/workshops"
                element={< EditWorkshopPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/tools"
                element={< EditToolsPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/open-problems"
                element={< EditOpenProblemsPage />}
            />

            <
                Route path="/rewriting/intranet/edit-website/competitions"
                element={< EditCompetitionsPage />}
            />

            <
                Route path="/rewriting/intranet/manage-accounts"
                element={< ManageAccountsPage />}
            />
            <Route path="/rewriting/intranet/archive"
                element={< Archive />}
            />
            <
                Route path="/rewriting/temp/intranet/account"
                element={< MyAccountPage />}
            /> <
                Route path="/rewriting/login"
                element={< LoginPageRef />}
            />
            <Route path="/rewriting/confirm/:token" element={< ConfirmAccountPage />} />
            <Route path="/rewriting/reset-password" element={< ResetPasswordPage />} />
            <Route path="/rewriting/reset-password/:token" element={< ResetPasswordPage2 />} />


            { /* Redirect pages if no route matches*/} <
                Route path="/*"
                element={< SplashPageNavigation />}
            /> <
                Route path="/rewriting/temp/rewriting/*"
                element={< TermRewritingIntroductionPage />}
            />  <
                Route path="/rewriting/intranet/edit-website/*"
                element={< EditWebsitePage />}
            /> <
                Route path="/rewriting/temp/lambda-calculus/*"
                element={< LambdaCalculusMainPage />}
            /> <
                Route path="/rewriting/temp/logic/*"
                element={< LogicIntroductionPage />}
            /> </Routes >
    </UserContextProvider>
    );
};

export default App;