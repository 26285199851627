import Box from "@mui/material/Box";
import IntranetNavBar from "../../../components/IntranetNavBar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import AddEntry from "../../../components/AddEntry";
import {
  Breadcrumbs,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import EditEntry from "../../../components/EditEntry";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteEntry from "../../../components/DeleteEntry";
import DrawerHeader from "../../../components/DrawerHeader";
import { NavLink } from "react-router-dom";
import API_URL from "../../../constants";

const EditCompetitionsPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [wantToDelete, setWantToDelete] = useState(false);
  const [counter, setCounter] = useState(0);
  const [dataEdited, setDataEdited] = useState([]);
  const name = "competition";

  const fetchData = () => {
    setLoading(true);
    fetch(API_URL + name).then((response) => {
      response.json().then((data) => {
        setData(data);
      });
      response.ok && setLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    setWantToEdit(false);
    setWantToDelete(false);
  }, []);

  const filteredData = data.map((row, index) => ({
    id: index + 1,
    _id: row._id,
    name: row.name,
    website: row.website,
  }));

  const entries = [
    {
      name: "name",
      displayname: "Name",
      type: "short-text",
    },
    {
      name: "website",
      displayname: "Website",
      type: "short-text",
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 130,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "website",
      headerName: "Website",
      width: 160,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "edit",
      headerName: "",

      renderCell: (params) => {
        return (
          <Tooltip title="Edit">
            <IconButton
              style={{ color: "#1565C0" }}
              onClick={(evt) => {
                evt.preventDefault();
                setWantToEdit(true);
                setWantToDelete(false);
                setCounter((prevCounter) => prevCounter + 1);
                setDataEdited(filteredData[params.row.id - 1]);
              }}
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "delete",
      headerName: "",

      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              style={{ background: "#ffcdcd" }}
              onClick={(evt) => {
                evt.preventDefault();
                setWantToDelete(true);
                setWantToEdit(false);
                setCounter((prevCounter) => prevCounter + 1);
                setDataEdited(filteredData[params.row.id - 1]);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <IntranetNavBar entitled="Edit website > Competitions" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "100%",
          overflowX: "scroll",
        }}
      >
        <DrawerHeader />
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink
            underline="none"
            color="black"
            to="/rewriting/intranet/edit-website"
          >
            Edit website
          </NavLink>
          <Typography color="text.primary">Competitions</Typography>
        </Breadcrumbs>
        {wantToEdit && (
          <>
            <EditEntry
              name={name}
              entries={entries}
              key={"editentry" + counter}
              data={dataEdited}
              onSuccess={fetchData}
            />
          </>
        )}
        {wantToDelete && (
          <>
            <DeleteEntry
              name={name}
              entries={entries}
              key={"editentry" + counter}
              data={dataEdited}
              onSuccess={fetchData}
            />
          </>
        )}

        {loading ? (
          <>
            Loading data...
            <LinearProgress />{" "}
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <AddEntry name={name} entries={entries}  onSuccess={fetchData}/>
            </Box>
            <DataTable name={name} columns={columns} rows={filteredData} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default EditCompetitionsPage;
