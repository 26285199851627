import React from "react";

const Section = (props) => {
  return (
    <div className="section-container">
      <h2>{props.title}</h2>
      <div className="content">
        {props.content.map((text, index) => {
          return <p key={index}>{text}</p>;
        })}
      </div>
    </div>
  );
};

export default Section;
