export const NavBarPath = [
  {
    name: "Introduction",
    path: "/rewriting/introduction",
  },
  {
    name: "Open problems",
    path: "/rewriting/open-problems",
  },
  {
    name: "Researchers",
    path: "/rewriting/researchers",
  },
  {
    name: "Conferences",
    path: "/rewriting/conferences",
  },
  {
    name: "Workshops",
    path: "/rewriting/workshops",
  },
  {
    name: "Tools",
    path: "/rewriting/tools",
  },
  {
    name: "Companies",
    path: "/rewriting/companies",
  },
  {
    name: "Competitions",
    path: "/rewriting/competitions",
  },
  // {
  //   name: "Mailing lists",
  //   path: "/rewriting/mailing-lists",
  // },
  
  {
    name: "Resources",
    path: "/rewriting/resources",
  },
  {
    name: "IFIP WG 1.6",
    path: "https://ifip-wg-rewriting.cs.ru.nl/",
  },
];
