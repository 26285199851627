import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import DataTable from "../../../../components/DataTable";
import AddEntry from "../../../../components/AddEntry";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import EditEntry from "../../../../components/EditEntry";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteEntry from "../../../../components/DeleteEntry";
import API_URL from "../../../../constants";


const EditBooksPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [wantToDelete, setWantToDelete] = useState(false);
  const [counter, setCounter] = useState(0);
  const [dataEdited, setDataEdited] = useState([]);
  const name = "book";

  const fetchData = () => {
    setLoading(true);
    fetch(API_URL + name).then((response) => {
      response.json().then((data) => {
        setData(data);
      });
      response.ok && setLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    setWantToEdit(false);
    setWantToDelete(false);
  }, []);

  const filteredData =
    data.length > 0 &&
    data.map((row, index) => ({
      id: index + 1,
      _id: row._id,
      name: row.name,
      description: row.description,
      link: row.link,
      tags: row.tags,
    }));

  const entries = [
    {
      name: "name",
      displayname: "Name",
      type: "short-text",
    },
    {
      name: "description",
      displayname: "Description",
      type: "multiline",
    },
    {
      name: "link",
      displayname: "Link",
      type: "short-text",
    },
    {
      name: "tags",
      displayname: "Tag",
      type: "short-text",
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 130,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "link",
      headerName: "Link",
      width: 160,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 160,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value.join(", ")}</div>
      ),
    },
    {
      field: "edit",
      headerName: "",

      renderCell: (params) => {
        return (
          <Tooltip title="Edit">
            <IconButton
              style={{ color: "#1565C0" }}
              onClick={(evt) => {
                evt.preventDefault();
                setWantToEdit(true);
                setWantToDelete(false);
                setCounter((prevCounter) => prevCounter + 1);
                setDataEdited(filteredData[params.row.id - 1]);
              }}
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "delete",
      headerName: "",

      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              style={{ background: "#ffcdcd" }}
              onClick={(evt) => {
                evt.preventDefault();
                setWantToDelete(true);
                setWantToEdit(false);
                setCounter((prevCounter) => prevCounter + 1);
                setDataEdited(filteredData[params.row.id - 1]);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        maxWidth: "100%",
      }}
    >
      {wantToEdit && (
        <>
          <EditEntry
            name={name}
            entries={entries}
            key={"editentry" + counter}
            data={dataEdited}
            onSuccess={fetchData}
          />
        </>
      )}
      {wantToDelete && (
        <>
          <DeleteEntry
            name={name}
            entries={entries}
            key={"deleteentry" + counter}
            data={dataEdited}
            onSuccess={fetchData}
          />
        </>
      )}

      {loading ? (
        <>
          Loading data...
          <LinearProgress />{" "}
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <AddEntry name={name} entries={entries}  onSuccess={fetchData} />
          </Box>
          <DataTable name={name} columns={columns} rows={filteredData} />
        </>
      )}
    </Box>
  );
};

export default EditBooksPage;
