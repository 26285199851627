import React, { useEffect } from "react";
import NavBar from "../../../../components/NavBar";
import Title from "../../../../components/Title";
import Path from "../../../../components/Path";
import { NavLink } from "react-router-dom";
import Ressource from "../../../../components/Ressource";
import Footer from "../../../../components/Footer";

const TermRewritingLearningRessourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Term Rewriting",
      path: "/rewriting/temp/term-rewriting/introduction",
    },
    {
      name: "Ressources",
      path: "/rewriting/temp/term-rewriting/ressources",
    },
    {
      name: "Learning ressources",
      path: "/rewriting/temp/term-rewriting/ressources/learning-ressources",
    },
  ];

  const links = [
    {
      path: "/rewriting/temp/term-rewriting/ressources/learning-ressources/books",
      title: "Books",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, earum.",
    },
    {
      path: "/rewriting/temp/term-rewriting/ressources/learning-ressources/surveys",
      title: "Surveys",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, earum.",
    },
    {
      path: "/rewriting/temp/term-rewriting/ressources/learning-ressources/courses",
      title: "Courses",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, earum.",
    },
  ];

  return (
    <div className="container">
      <NavBar theme="rewriting" />
      <div className="main">
        <Path paths={paths} branch="rewriting" />
        <Title title="Learning Ressources" />
        <div className="ressources-cards-container">
          {links.map(({ path, title, description }) => (
            <NavLink key={title} to={path}>
              <Ressource ressourceTitle={title} description={description} />
            </NavLink>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermRewritingLearningRessourcesPage;
