import React, { useEffect } from "react";
import NavBar from "../../../../../components/NavBar";
import Title from "../../../../../components/Title";
import Section from "../../../../../components/Section";
import Path from "../../../../../components/Path";
import Footer from "../../../../../components/Footer";

const LogicBooksPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sections = [
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Logic and Computation",
      path: "/rewriting/temp/logic-and-computation/introduction",
    },
    {
      name: "Ressources",
      path: "/rewriting/temp/logic-and-computation/ressources",
    },
    {
      name: "Learning ressources",
      path: "/rewriting/temp/logic-and-computation/ressources/learning-ressources",
    },
    {
      name: "Books",
      path: "/rewriting/temp/logic-and-computation/ressources/learning-ressources/books",
    },
  ];

  return (
    <div className="container">
      <NavBar theme="logic" />
      <div className="main">
        <Path paths={paths} branch="logic" />
        <Title title="Books" />
        {sections.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default LogicBooksPage;
