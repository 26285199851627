import React, { useEffect } from "react";
import NavBar from "../../../components/NavBar";
import Title from "../../../components/Title";
import Ressource from "../../../components/Ressource";
import { NavLink } from "react-router-dom";
import Path from "../../../components/Path";
import Footer from "../../../components/Footer";

const LambdaCalculusRessourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const links = [
    {
      path: "/rewriting/temp/lambda-calculus/ressources/learning-ressources",
      title: "Learning ressources",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, earum.",
    },

    {
      path: "/rewriting/temp/lambda-calculus/ressources/external-ressources",
      title: "External ressources",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, earum.",
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Lambda Calculus",
      path: "/rewriting/temp/lambda-calculus/introduction",
    },
    {
      name: "Introduction",
      path: "/rewriting/temp/lambda-calculus/ressources",
    },
  ];

  return (
    <div className="container">
      <NavBar
        theme="lambda-calculus"
      />

      <div className="main">
        <Path paths={paths} branch="lambda-calculus" />
        <Title title="Ressources" />
        <div className="ressources-cards-container">
          {links.map(({ path, title, description }) => (
            <NavLink key={title} to={path}>
              <Ressource ressourceTitle={title} description={description} />
            </NavLink>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LambdaCalculusRessourcesPage;
