import React, { useEffect } from "react";
import NavBar from "../../../../../components/NavBar";
import Title from "../../../../../components/Title";
import Section from "../../../../../components/Section";
import Path from "../../../../../components/Path";
import Footer from "../../../../../components/Footer";

const LambdaCalculusSurveysPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sections = [
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Lambda Calculus",
      path: "/rewriting/temp/lambda-calculus/introduction",
    },
    {
      name: "Ressources",
      path: "/rewriting/temp/lambda-calculus/ressources",
    },
    {
      name: "Learning ressources",
      path: "/rewriting/temp/lambda-calculus/ressources/learning-ressources",
    },
    {
      name: "Surveys",
      path: "/rewriting/temp/lambda-calculus/ressources/learning-ressources/surveys",
    },
  ];

  return (
    <div className="container">
      <NavBar theme="lambda-calculus" />
      <div className="main">
        <Path paths={paths} branch="lambda-calculus" />
        <Title title="Surveys" />
        {sections.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default LambdaCalculusSurveysPage;
