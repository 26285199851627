import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../UserContext";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import IntranetNavBar from "./IntranetNavBar";
import DrawerHeader from "./DrawerHeader";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";

const EditUserInfo = (props) => {
  const [value, setValue] = useState("");
  const [valueReenter, setValueReenter] = useState("");
  const [error, setError] = useState("");
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  async function handleSubmit(event) {
    event.preventDefault();
    if (value !== valueReenter) {
      setError("Both fields must have the same value.");
      setRefresh(refresh + 1);
      setValue("");
      setValueReenter("");
      return;
    }
    if (props.entitled === "email") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setError("The email address is not valid.");
        setRefresh(refresh + 1);
        setValue("");
        setValueReenter("");
      } else {
        setError("");
        const data = new FormData();
        data.append("name", "email");
        data.append("value", value);
        const response = await fetch(
          "http://localhost:5000/api/user/" + userInfo.username,
          {
            method: "PUT",
            body: data,
          }
        );

        response.ok && navigate("/rewriting/intranet/my-account");
      }
    } else if (props.entitled === "password") {
      setError("");
      const data = new FormData();
      data.append("name", "password");
      data.append("value", value);
      const response = await fetch(
        "http://localhost:5000/api/user/" + userInfo.username,
        {
          method: "PUT",
          body: data,
        }
      );
      response.ok && navigate("/rewriting/intranet/my-account");
    }
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <IntranetNavBar entitled="Edit website" />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* show account informations */}
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Change {props.entitled}
        </Typography>
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <Paper
            sx={{
              p: 5,
              display: "flex",
              flexDirection: "column",
              maxWidth: "400px",
              gap: "10px",
            }}
            variant="outlined"
          >
            {error !== "" ? (
              <>
                <Typography color="error">{error}</Typography>
                <TextField
                  id="outlined-basic"
                  label={"New " + props.entitled}
                  variant="outlined"
                  onChange={(event) => setValue(event.target.value)}
                  type={props.entitled === "email" ? "email" : "password"}
                  error
                  key={refresh}
                  defaultValue={error && ""}
                />
                <TextField
                  id="outlined-basic2"
                  label={"Reenter new " + props.entitled}
                  variant="outlined"
                  onChange={(event2) => setValueReenter(event2.target.value)}
                  type={props.entitled === "email" ? "email" : "password"}
                  error
                  key={refresh + 1}
                  defaultValue={error && ""}
                />
              </>
            ) : (
              <>
                <TextField
                  id="outlined-basic"
                  label={"New " + props.entitled}
                  variant="outlined"
                  type={props.entitled === "email" ? "email" : "password"}
                  onChange={(event) => setValue(event.target.value)}
                />
                <TextField
                  id="outlined-basic2"
                  label={"Reenter new " + props.entitled}
                  variant="outlined"
                  type={props.entitled === "email" ? "email" : "password"}
                  onChange={(event) => setValueReenter(event.target.value)}
                />
              </>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  background: "#CFCFCF",
                  "&:hover, &:focus": {
                    background: "#A1A1A1",
                  },
                }}
                onClick={() => navigate("/rewriting/intranet/my-account")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                type="submit"
                endIcon={<ModeEditIcon />}
              >
                Edit
              </Button>
            </Box>
          </Paper>
        </form>
      </Box>
    </Box>
  );
};

export default EditUserInfo;
