import * as React from "react";
import Box from "@mui/material/Box";
import IntranetNavBar from "../../../components/IntranetNavBar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Breadcrumbs, Tab } from "@mui/material";
import DrawerHeader from "../../../components/DrawerHeader";
import { NavLink } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EditBooksPage from "./ressources/EditBooksPage";
import EditCoursesPage from "./ressources/EditCoursesPage";
import EditOthersPage from "./ressources/EditOthersPage";

const EditResourcesPage = () => {
  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <IntranetNavBar entitled="Edit website > Resources" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "100%",
          overflowX: "scroll",
        }}
      >
        <DrawerHeader />
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink
            underline="none"
            color="black"
            to="/rewriting/intranet/edit-website"
          >
            Edit website
          </NavLink>
          <Typography color="text.primary">Resources</Typography>
        </Breadcrumbs>
        <Box sx={{ width: "100%", typography: "body1", marginTop: "1rem" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Books" value="0" />
                <Tab label="Course" value="1" />
                <Tab label="Others" value="2" />
              </TabList>
            </Box>
            <TabPanel value="0">
              <EditBooksPage />
            </TabPanel>
            <TabPanel value="1">
              <EditCoursesPage />
            </TabPanel>
            <TabPanel value="2">
              <EditOthersPage />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
};

export default EditResourcesPage;
