import React from "react";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { styled } from "@mui/material/styles";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  marginBottom: "10px",
  lineHeight: 1.5,
  backgroundColor: "#27AE60",
  borderColor: "#1F8B4B",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#2ECC71",
    borderColor: "#249B5E",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#229954",
    borderColor: "#1B6B46",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem #229954",
  },
});

const AddButton = (props) => {
  return (
    <BootstrapButton
      variant="contained"
      endIcon={<AddCircleOutlineIcon />}
      onClick={props.function}
    >
      {props.name}
    </BootstrapButton>
  );
};

export default AddButton;
