export const TempTermRewritingPath = [
  {
    id: 1,
    name: "Introduction",
    path: "/rewriting/temp/term-rewriting/introduction",
  },
  {
    id: 2,
    name: "Ressources",
    path: "/rewriting/temp/term-rewriting/ressources",
  },
];
