import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>Hosted by Gitlab Inria, 2023</p>
      </div>
      <div className="authors">
        <p>
          Made by{" "}
          <NavLink
            to="http://www-sop.inria.fr/members/Luigi.Liquori"
            target="_blank"
          >
            Luigi Liquori
          </NavLink>
          , Inria {" & "}
          <NavLink to="https://www.alexis-rosset.fr" target="_blank">
            Alexis Rosset,
          </NavLink>
          <NavLink to="" target="_blank">
           Khadija BENDIB
          </NavLink>
          , Université Nice Côte d'Azur
        </p>
        

        <p>Please send modifications or improvements to lui@inria.fr </p>
      </div>
    </div>
  );
};

export default Footer;
