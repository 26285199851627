import { useEffect } from "react";
import Box from "@mui/material/Box";
import IntranetNavBar from "../../components/IntranetNavBar";
import Typography from "@mui/material/Typography";
import DrawerHeader from "../../components/DrawerHeader";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { Button, Divider, Paper } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";

const MyAccountPage = () => {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <IntranetNavBar entitled="My account" />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            borderRadius: "10px",
            sm: { flexDirection: "row" },
          }}
          variant="outlined"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              p: 2,
              justifyContent: "space-between",
              "@media (max-width: 500px)": {
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Email :
              </Typography>
              <Typography variant="body1">{userInfo.username}</Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              endIcon={<ModeEditIcon />}
              onClick={() => navigate("/rewriting/intranet/edit-email")}
            >
              Edit
            </Button>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              p: 2,
              justifyContent: "space-between",
              "@media (max-width: 500px)": {
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Password :
              </Typography>
              <Typography variant="body1">********</Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              endIcon={<ModeEditIcon />}
              onClick={() => navigate("/rewriting/intranet/edit-password")}
            >
              Edit
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
export default MyAccountPage;