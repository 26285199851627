import Box from "@mui/material/Box";
import IntranetNavBar from "../../components/IntranetNavBar";

import { useEffect } from "react";
import PageList from "../../components/PageList";
import DrawerHeader from "../../components/DrawerHeader";

const EditWebsitePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        paddingLeft: "70px",
      }}
    >
      <IntranetNavBar entitled="Edit website" />
      <DrawerHeader />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <PageList />
      </Box>
    </Box>
  );
};

export default EditWebsitePage;
