import React, { useState } from "react";
import Title from "../components/Title";
import Footer from "../components/Footer";
import TextField from "@mui/material/TextField";
import { Button, Box, CircularProgress, Alert } from "@mui/material";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import API_URL from "../constants";

const RegisterPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  async function register(ev) {
    ev.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await fetch(API_URL + `register`, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        setSuccess("Register successful. Please check your email for confirmation.");
        setTimeout(() => {
          navigate("rewriting");
        }, 3000); // Attendre 3 secondes avant la redirection
      } else {
        const data = await response.json();
        setError(data.message || "Register failed");
      }
    } catch (err) {
      console.error("Erreur lors de l'inscription:", err);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="login-page">
      <div className="login-card">
        <Title title="Intranet" />
        <form className="register" onSubmit={register}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            name="username"
            label="Email"
            type="email"
            id="email"
            autoComplete="current-email"
            maxRows={1}
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            maxRows={1}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={() => navigate("/rewriting")}
              variant="contained"
              sx={{ minWidth: "45%", mt: 3, mb: 2 }}
            >
              <IoArrowForwardOutline style={{ marginLeft: "5px", transform: "rotate(180deg)" }} size={20} />
              Return to Home
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ minWidth: "45%", mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Register"}
              {!loading && <IoArrowForwardOutline style={{ marginLeft: "5px" }} size={20} />}
            </Button>
          </Box>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterPage;
