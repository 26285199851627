import * as React from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
const PageList = () => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    textAlign: "center",
    color: "#303030",
    height: "100px",
    boxShadow:
      "0px 3px 1px -2px rgba(224,224,224,1),0px 2px 2px 0px rgba(224,224,224,1),0px 1px 5px 0px rgba(224,224,224,1)",
    transition: "0.25s",
    "&:hover": {
      background: "#e6e6e6",
      cursor: "pointer",
    },
  }));
  const paths = [
    {
      name: "Introduction",
      path: "/rewriting/intranet/edit-website/introduction",
    },
    {
      name: "Open problems",
      path: "/rewriting/intranet/edit-website/open-problems",
    },
    {
      name: "Researchers",
      path: "/rewriting/intranet/edit-website/researchers",
    },
    {
      name: "Conferences",
      path: "/rewriting/intranet/edit-website/conferences",
    },
    {
      name: "Workshops",
      path: "/rewriting/intranet/edit-website/workshops",
    },
    {
      name: "Tools",
      path: "/rewriting/intranet/edit-website/tools",
    },
    {
      name: "Companies",
      path: "/rewriting/intranet/edit-website/companies",
    },
    {
      name: "Competitions",
      path: "/rewriting/intranet/edit-website/competitions",
    },
    // {
    //   name: "Mailing lists",
    //   path: "/rewriting/intranet/edit-website/mailing-lists",
    // },
    {
      name: "Resources",
      path: "/rewriting/intranet/edit-website/resources",
    },
  ];

  return (
    <Grid container spacing={2}>
      {paths.map((path, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <NavLink
            to={path.path}
            style={{ textDecoration: "none", color: "#303030" }}
          >
            <Item>{path.name}</Item>
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );
};

export default PageList;
