import Box from "@mui/material/Box";
import IntranetNavBar from "../../../components/IntranetNavBar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Breadcrumbs, LinearProgress, Paper } from "@mui/material";
import EditEntry from "../../../components/EditEntry";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DrawerHeader from "../../../components/DrawerHeader";
import { NavLink } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import AddEntry from "../../../components/AddEntry";
import API_URL from "../../../constants";
const EditIntroductionPage = () => {
  const [loading, setLoading] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [counter, setCounter] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [fetchDataSuccessful, setFetchDataSuccessful] = useState(false);
  
  const fetchData = () => {
    setLoading(true);
    fetch(API_URL+"text/introduction")
      .then((response) => response.json())
      .then((data) => {
        const nonArchivedData = data.filter(item => !item.Archive);

        if (nonArchivedData.length !== 0) {
          setFilteredData({
            _id: nonArchivedData[0]["_id"],
            name: nonArchivedData[0]["name"],
            content: nonArchivedData[0]["content"],
            updatedAt: nonArchivedData[0]["updatedAt"],

          });
          setFetchDataSuccessful(true);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setWantToEdit(false);
  }, [fetchDataSuccessful]);

  const entries = [
    {
      name: "name",
      displayname: "Name",
      type: "invisible",
    },
    {
      name: "content",
      displayname: "Content",
      type: "editor",
    },
  ];

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <IntranetNavBar entitled="Edit website > Introduction" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "100%",
          overflowX: "scroll",
        }}
      >
        <DrawerHeader />
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink
            underline="none"
            color="black"
            to="/rewriting/intranet/edit-website"
          >
            Edit website
          </NavLink>
          <Typography color="text.primary">Introduction</Typography>
        </Breadcrumbs>

        {wantToEdit && (
          <>
            <EditEntry
              name="text/introduction"
              entries={entries}
              key={"editentry" + counter}
              data={filteredData}
              onSuccess={fetchData}
            />
          </>
        )}

        {loading ? (
          <>
            Loading data...
            <LinearProgress />
          </>
        ) : (
          <>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                gap: "10px",
                width: "100%",
                padding: "10px",
                borderRadius: "10px",
              }}
              variant="outlined"
            >
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: filteredData.content }}
              />

              {/* si data est vide */}
              {!fetchDataSuccessful ? (
                <AddEntry text="true" name="introduction" entries={entries}  onSuccess={fetchData}/>
              ) : (
                <Button
                variant="contained"
                endIcon={<ModeEditIcon />}
                sx={{ width: "fit-content" }}
                onClick={(evt) => {
                  evt.preventDefault();
                  setWantToEdit(true);
                  setCounter((prevCounter) => prevCounter + 1);
                }}
              >
               Edit
              </Button>
              
              )}
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EditIntroductionPage;
