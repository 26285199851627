import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { IoArrowForwardOutline } from "react-icons/io5";
import Title from "../components/Title";
import Footer from "../components/Footer";
import API_URL from "../constants";
import { UserContext } from "../UserContext";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const LoginPageRef = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { setUserInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
  }, [navigate]);

  async function login(ev) {
    ev.preventDefault();
    const response = await fetch(API_URL + "login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (response.ok) {
      const userInfo = await response.json();
      localStorage.setItem("authenticated", true);
      localStorage.setItem("role", userInfo.role); // Stockage du rôle
      setUserInfo(userInfo);
      navigate("/rewriting/intranet/edit-website"); // Redirection vers la page intranet
    } else {
      setError(true);
    }
  }

  return (
    <div className="login-page">
      <div className="login-card">
        <Title title="Intranet" />
        <form
          className="register"
          onSubmit={login}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          {error ? (
            <TextField
              margin="normal"
              fullWidth
              error
              helperText="Incorrect entry."
              name="Email"
              label="Email"
              type="email"
              id="email"
              autoComplete="current-email"
              maxRows={1}
              onChange={(e) => setUsername(e.target.value)}
            />
          ) : (
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label="Email"
              type="email"
              id="email"
              autoComplete="current-email"
              maxRows={1}
              onChange={(e) => setUsername(e.target.value)}
            />
          )}
          {error ? (
            <TextField
              margin="normal"
              error
              helperText="Incorrect entry."
              fullWidth
              name="Password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              maxRows={1}
              onChange={(e) => setPassword(e.target.value)}
            />
          ) : (
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              maxRows={1}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" align="center">
              <Link
                to="/rewriting/reset-password"
                style={{
                  textDecoration: "none",
                  color: "primary.main", // Utilisez votre couleur primaire ou une autre couleur de votre thème
                }}
              >
                Forgot Password
              </Link>
            </Typography>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={() => navigate("/rewriting")}
              fullWidth
              variant="contained"
              sx={{ minWidth: "200px", mr: 2 }}
            >
              <IoArrowForwardOutline
                size={30}
                style={{ transform: "rotate(180deg)" }}
              />
              Return to Home
            </Button>
            <Button
              onClick={() => navigate("/rewriting/register")}
              fullWidth
              variant="contained"
              sx={{ minWidth: "200px" }}
            >
              Register
              <IoArrowForwardOutline size={30} />
            </Button>
          </Box>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPageRef;
