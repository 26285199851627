import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import MediumCard from "../../components/MediumCard";
import { Box, Skeleton, Typography } from "@mui/material";
import Navigation from "../../components/temp/Navigation";
import API_URL from "../../constants";
//
const CompetitionsPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); // Set initial value to true

  const fetchData = () => {
    setLoading(true);
    fetch(API_URL + "competition")
      .then((response) => {
        response.json().then((data) => {
          setData(data);
        });
        response.ok && setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <Navigation name="Competitions" />
      <div
        className="main"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Competitions
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            width: "600px",
            "@media (max-width: 650px)": {
              width: "100%",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Skeleton variant="rounded" />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
            </Box>
          ) : (
            <>
              {data.length === 0 ? (
                <Typography variant="h6" sx={{ mt: 2, width: "100%" }}>
                  No results found
                </Typography>
              ) : (
                <>
                  {data.map((competition, index) => (
                    <div key={index}>
                      <MediumCard
                        name={competition.name}
                        website={competition.website}
                      />
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default CompetitionsPage;
