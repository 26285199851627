export const TempLogicPath = [
  {
    id: 1,
    name: "Introduction",
    path: "/rewriting/temp/logic-and-computation/introduction",
  },
  {
    id: 2,
    name: "Ressources",
    path: "/rewriting/temp/logic-and-computation/ressources",
  },
];
