import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { IoArrowForwardOutline } from "react-icons/io5";
import Title from "../components/Title";
import Footer from "../components/Footer";
import API_URL from "../constants";

const ResetPasswordPage2 = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async (ev) => {
    ev.preventDefault();
    setSuccess("");
    setError(""); // Clear previous errors

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}reset-password`, {
        method: "POST",
        body: JSON.stringify({ token, newPassword }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        setSuccess("Password has been reset successfully.");
        setTimeout(() => navigate("/rewriting/login"), 4000); // Wait 4 seconds before redirecting
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to reset password.");
      }
    } catch (err) {
      console.error("Network error:", err);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-page">
      <div className="login-card">
        <Title title="Reset Password" />
        <form
          className="reset-password-form"
          onSubmit={handlePasswordReset}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          {/* Display success message if available */}
          {success && (
            <div style={{ color: 'green', marginBottom: '1rem', textAlign: 'center' }}>
              {success}
            </div>
          )}
          {/* Display error message if available */}
          {error && (
            <div style={{ color: 'red', marginBottom: '1rem', textAlign: 'center' }}>
              {error}
            </div>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            error={!!error} // Only show error state if there is an error
            helperText={error ? error : ""}
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="new-password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            error={!!error} // Only show error state if there is an error
            helperText={error ? error : ""}
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="confirm-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={() => navigate("/rewriting/login")} // Updated path here
              fullWidth
              variant="contained"
              sx={{ minWidth: "200px", mr: 2 }}
            >
              <IoArrowForwardOutline
                size={30}
                style={{ transform: "rotate(180deg)" }}
              />
              Back to Login
            </Button>
          </Box>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordPage2;
