import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { IoArrowForwardOutline } from "react-icons/io5";
import Title from "../components/Title";
import Footer from "../components/Footer";
import API_URL from "../constants";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();

  const requestPasswordReset = async (ev) => {
    ev.preventDefault();
    setSuccess("");
    setMessage(""); // Clear previous messages

    try {
      const response = await fetch(`${API_URL}request-password-reset`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        setSuccess("A reset link has been sent to your email address.");
        setError(false);
        setTimeout(() => navigate("/rewriting"), 4000); // Wait 4 seconds before redirecting
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || "Failed to send reset link.");
        setError(true);
      }
    } catch (err) {
      console.error("Network error:", err);
      setMessage("An error occurred. Please try again.");
      setError(true);
    }
  };

  return (
    <div className="login-page">
      <div className="login-card">
        <Title title="Reset Password" />
        <form
          className="register"
          onSubmit={requestPasswordReset}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          {/* Display success message if available */}
          {success && (
            <div style={{ color: 'green', marginBottom: '1rem', textAlign: 'center' }}>
              {success}
            </div>
          )}
          {/* Display error message if available */}
          {error && (
            <div style={{ color: 'red', marginBottom: '1rem', textAlign: 'center' }}>
              {message}
            </div>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            error={error}
            helperText={error ? message : ""}
            name="email"
            label="Email"
            type="email"
            id="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Reset Link
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={() => navigate("/rewriting/login")}
              fullWidth
              variant="contained"
              sx={{ minWidth: "200px", mr: 2 }}
            >
              <IoArrowForwardOutline
                size={30}
                style={{ transform: "rotate(180deg)" }}
              />
              Back to Login
            </Button>
          </Box>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordPage;
