import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../components/Footer";
import { Box, Paper, Typography } from "@mui/material";
import Navigation from "../../components/temp/Navigation";

const ResourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <Navigation name="Resources" />
      <div
        className="main"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Resources
        </Typography>
        <div className="ressources-cards-container">
          <Box
            sx={{
              width: "100%",
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {[
              { name: "Books", path: "/rewriting/resources/books" },
              { name: "Courses", path: "/rewriting/resources/courses" },
              { name: "Others", path: "/rewriting/resources/others" },
            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                style={{
                  display: "flex",
                  textDecoration: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "600px",
                    p: 2,
                    borderRadius: "10px",
                    transition: "all 0.2s ease-in-out",
                    cursor: "pointer",
                    boxShadow:
                      "0px 3px 1px -2px rgba(224,224,224,1),0px 2px 2px 0px rgba(224,224,224,1),0px 1px 5px 0px rgba(224,224,224,1)",

                    "@media (max-width: 650px)": {
                      width: "100%",
                    },
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  {item.name}
                </Paper>
              </NavLink>
            ))}
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResourcesPage;
