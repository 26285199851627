import React from "react";
import Tag from "./Tag";
import { NavLink } from "react-router-dom";

const Card = (props) => {
  const { title, url, tags /*author, date*/ } = props;
  return (
    <NavLink to={url} className="industrial-application-card" target="_blank">
      <div className="name">{title} </div>
      <div className="tags-container">
        {tags.map((tag, index) => (
          <Tag key={index} name={tag} />
        ))}
      </div>
      {/* <p>{author}</p>
      <p>{date}</p> */}
    </NavLink>
  );
};

export default Card;
