import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { NavBarPath } from "./NavBarPath";
import { FiExternalLink } from "react-icons/fi";

const NavBar = (props) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  function toggleMenu() {
    setIsActive(!isActive);
  }

  const logout = () => {
    localStorage.removeItem("authenticated");
    navigate("/login");
  };

  const location = useLocation();

  return (
    <div className="rewriting-navbar-container">
      <div className="navbar">
        <div className="bars-container">
          <div
            className={`bars ${isActive ? "active" : ""}`}
            onClick={toggleMenu}
          >
            {isActive ? <FaTimes size={20} /> : <FaBars size={20} />}
          </div>
        </div>
        <ul className={`nav-items ${isActive ? "active" : ""}`}>
          {NavBarPath.map((path, index) => (
            <li key={index}>
              {path.name === "IFIP WG 1.6" ? (
                <NavLink
                  to={path.path}
                  className={`nav-link ${props.theme} ${
                    location.pathname === path.path ? "selected" : ""
                  }`}
                  target="_blank"
                >
                  {path.name} <FiExternalLink size={15} />
                </NavLink>
              ) : (
                <NavLink
                  to={path.path}
                  className={`nav-link ${props.theme} ${
                    location.pathname === path.path ? "selected" : ""
                  }`}
                >
                  {path.name}
                </NavLink>
              )}
            </li>
          ))}
          {props.theme === "intranet" && (
            <div className="logout-container">
              <button onClick={logout}>
                Logout <MdOutlineLogout size={25} />
              </button>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
