import React, { useEffect } from "react";
import Title from "../../components/Title";
import Path from "../../components/Path";
import Footer from "../../components/Footer";
import Section from "../../components/Section";

const TempConferencesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sections = [
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Conferences",
      path: "/rewriting/temp/conferences",
    },
  ];

  return (
    <div className="container">
      <div className="main no-nav-bar">
        <Path paths={paths} />
        <Title title="Conferences" />
        {sections.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default TempConferencesPage;
