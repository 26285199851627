import React, { useEffect } from "react";
import NavBar from "../../../../../components/NavBar";
import Title from "../../../../../components/Title";
import Section from "../../../../../components/Section";
import Path from "../../../../../components/Path";
import Footer from "../../../../../components/Footer";

const TermRewritingBooksPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sections = [
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
    {
      title: "Section",
      content: ["Text"],
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Term Rewriting",
      path: "/rewriting/temp/term-rewriting/introduction",
    },
    {
      name: "Ressources",
      path: "/rewriting/temp/term-rewriting/ressources",
    },
    {
      name: "Learning ressources",
      path: "/rewriting/temp/term-rewriting/ressources/learning-ressources",
    },
    {
      name: "Surveys",
      path: "/rewriting/temp/term-rewriting/ressources/learning-ressources/books",
    },
  ];

  return (
    <div
      className="container
    "
    >
      <NavBar theme="rewriting" />
      <div className="main">
        <Path paths={paths} branch="rewriting" />
        <Title title="Books" />
        {sections.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default TermRewritingBooksPage;
