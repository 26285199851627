import React, { useEffect } from "react";
import Title from "../../components/Title";
import Card from "../../components/Card";
import Path from "../../components/Path";
import Footer from "../../components/Footer";

const TempResearchersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const links = [
    {
      title: "Researcher n°1",
      url: "",
      tags: ["Rewriting", "Logic"],
    },
    {
      title: "Researcher n°2",
      url: "",
      tags: ["Rewriting", "Lambda Calculus"],
    },
    {
      title: "Researcher n°3",
      url: "",
      tags: ["Lambda Calculus"],
    },
    {
      title: "Researcher n°4",
      url: "",
      tags: ["Logic"],
    },
    {
      title: "Researcher n°5",
      url: "",
      tags: ["Rewriting"],
    },
    {
      title: "Researcher n°6",
      url: "",
      tags: ["Rewriting"],
    },
    {
      title: "Researcher n°7",
      url: "",
      tags: ["Rewriting", "Logic", "Lambda Calculus"],
    },
    {
      title: "Researcher n°8",
      url: "",
      tags: ["Logic"],
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Researchers",
      path: "/rewriting/temp/researchers",
    },
  ];

  return (
    <div className="container">
      <div className="main no-nav-bar">
        <Path paths={paths} />
        <Title title="Researchers" />
        <div className="industrial-applications-cards-container">
          {links.map(({ title, url, tags }) => (
            <Card title={title} url={url} tags={tags} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TempResearchersPage;
