import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { TempTermRewritingPath } from "./temp/TempTermRewritingPath";
import { TempLambdaCalculusPath } from "./temp/TempLambdaCalculusPath";
import { TempLogicPath } from "./temp/TempLogicPath";

const NavBar = (props) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const paths =
    props.theme === "rewriting"
      ? TempTermRewritingPath
      : props.theme === "lambda-calculus"
      ? TempLambdaCalculusPath
      : props.theme === "logic"
      ? TempLogicPath
      : "";

  function toggleMenu() {
    setIsActive(!isActive);
  }

  const logout = () => {
    localStorage.removeItem("authenticated");
    navigate("/login");
  };

  const location = useLocation();

  return (
    <div className="navbar-container">
      <div className="navbar">
        <div className="bars-container">
          <div
            className={`bars ${isActive ? "active" : ""}`}
            onClick={toggleMenu}
          >
            {isActive ? <FaTimes size={20} /> : <FaBars size={20} />}
          </div>
        </div>
        <ul className={`nav-items ${isActive ? "active" : ""}`}>
          {paths.map((path) => (
            <li>
              <NavLink
                key={path.id}
                to={path.path}
                className={`nav-link ${props.theme} ${
                  location.pathname === path.path ? "selected" : ""
                }`}
              >
                {path.name}
              </NavLink>
            </li>
          ))}
          {props.theme === "intranet" && (
            <div className="logout-container">
              <button onClick={logout}>
                Logout <MdOutlineLogout size={25} />
              </button>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
