import React, { useState, useEffect, useCallback } from "react";
import IntranetNavBar from "../../components/IntranetNavBar";
import API_URL from "../../constants";
import '../../styles/Archive.scss';

const Archive = () => {
  const [loading, setLoading] = useState(true);
  const [archivedData, setArchivedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTextId, setCurrentTextId] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [itemToRestore, setItemToRestore] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_URL}text`);
      if (!response.ok) {
        throw new Error('La réponse réseau n\'était pas correcte');
      }
      const data = await response.json();
      setArchivedData(data);
      setFilteredData(data); // Set filtered data to initial data
      console.log("Données récupérées : ", data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Memorize the filterData function to prevent unnecessary re-renders
  const filterData = useCallback((term) => {
    if (term === '') {
      setFilteredData(archivedData);
    } else {
      const lowercasedTerm = term.toLowerCase();
      const filtered = archivedData.filter(item =>
        item.name.toLowerCase().includes(lowercasedTerm) ||
        item.content.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredData(filtered);
    }
  }, [archivedData]);

  useEffect(() => {
    filterData(searchTerm);
  }, [searchTerm, filterData]);

  const restoreItem = async (id) => {
    try {
      const response = await fetch(`${API_URL}text/${id}/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la restauration de l\'élément');
      }

      const result = await response.json();
      console.log("Résultat de la restauration : ", result);

      setArchivedData(archivedData.map(item =>
        item._id === result.archivedText._id ? result.archivedText : item._id === result.restoredText._id ? result.restoredText : item
      ));
      setFilteredData(filteredData.map(item =>
        item._id === result.archivedText._id ? result.archivedText : item._id === result.restoredText._id ? result.restoredText : item
      ));
      setCurrentTextId(id); // Définir le texte actuel comme le texte restauré
    } catch (error) {
      console.error("Erreur lors de la restauration de l'élément :", error);
    }
  };

  const deleteItem = async (id) => {
    try {
      const response = await fetch(`${API_URL}text/${id}/`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression de l\'élément');
      }

      console.log("Élément supprimé avec succès");

      setArchivedData(archivedData.filter(item => item._id !== id));
      setFilteredData(filteredData.filter(item => item._id !== id));
      setCurrentTextId(null); // Réinitialiser l'élément actuel
    } catch (error) {
      console.error("Erreur lors de la suppression de l'élément :", error);
    }
  };

  const handleRestore = async () => {
    if (itemToRestore) {
      await restoreItem(itemToRestore);
      fetchData();
      setItemToRestore(null);
      setShowConfirmDialog(false);
    }
  };

  const handleDelete = async () => {
    if (itemToDelete) {
      await deleteItem(itemToDelete);
      setItemToDelete(null);
      setShowDeleteConfirmDialog(false);
    }
  };

  return (
    <div className="archive-page">
      <IntranetNavBar entitled="Archive" />
      <div className="archive-content">
        <h1 className="archive-title">Archive</h1>
        
        {/* Search Bar */}
        <div className="search-container">
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>

        {loading ? (
          <p className="loading-text">Chargement...</p>
        ) : (
          <div className="archive-list-container">
            {filteredData.length === 0 ? (
              <p className="no-data-text">Aucun élément archivé trouvé.</p>
            ) : (
              <ul className="archive-list">
                {filteredData.map((item) => (
                  <li 
                    key={item._id} 
                    className={`archive-item ${item._id === currentTextId ? 'current' : ''}`} 
                    onClick={() => setCurrentTextId(item._id)} 
                  >
                    <h2 className="archive-item-title">{item.name}</h2>
                    <div className="archive-item-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                    <p className="archive-item-date">Dernière mise à jour : {new Date(item.updatedAt).toLocaleString()}</p>
                    <div className="archive-item-actions">
                      <button 
                        onClick={() => {
                          setItemToRestore(item._id);
                          setShowConfirmDialog(true);
                        }} 
                        className="restore-button"
                      >
                        Restaurer
                      </button>
                      <button 
                        onClick={() => {
                          setItemToDelete(item._id);
                          setShowDeleteConfirmDialog(true);
                        }} 
                        className="delete-button"
                      >
                        Supprimer
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>

      {/* Confirmation Dialog for Restore */}
      {showConfirmDialog && (
        <div className="confirm-dialog">
          <div className="confirm-dialog-content">
            <p>Êtes-vous sûr de vouloir restaurer cet élément ?</p>
            <button onClick={handleRestore} className="confirm-button">Oui</button>
            <button onClick={() => setShowConfirmDialog(false)} className="cancel-button">Non</button>
          </div>
        </div>
      )}

      {/* Confirmation Dialog for Delete */}
      {showDeleteConfirmDialog && (
        <div className="confirm-dialog">
          <div className="confirm-dialog-content">
            <p>Êtes-vous sûr de vouloir supprimer cet élément ?</p>
            <button onClick={handleDelete} className="confirm-button">Oui</button>
            <button onClick={() => setShowDeleteConfirmDialog(false)} className="cancel-button">Non</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Archive;
