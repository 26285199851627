import React, { useEffect } from "react";
import NavBar from "../../../components/NavBar";
import Title from "../../../components/Title";
import Ressource from "../../../components/Ressource";
import { NavLink } from "react-router-dom";
import Path from "../../../components/Path";
import Footer from "../../../components/Footer";

const LogicRessourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const links = [
    {
      path: "/rewriting/temp/logic-and-computation/ressources/learning-ressources",
      title: "Learning ressources",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, earum.",
    },

    {
      path: "/rewriting/temp/logic-and-computation/ressources/external-ressources",
      title: "External ressources",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, earum.",
    },
  ];

  const paths = [
    {
      name: "Home",
      path: "/rewriting/temp",
    },
    {
      name: "Logic and Computation",
      path: "/rewriting/temp/logic-and-computation/introduction",
    },
    {
      name: "Ressources",
      path: "/rewriting/temp/logic-and-computation/ressources",
    },
  ];

  return (
    <div className="container">
      <NavBar theme="logic" />

      <div className="main">
        <Path paths={paths} branch="logic" />
        <Title title="Ressources" />
        <div className="ressources-cards-container">
          {links.map(({ path, title, description }) => (
            <NavLink key={title} to={path}>
              <Ressource ressourceTitle={title} description={description} />
            </NavLink>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LogicRessourcesPage;
