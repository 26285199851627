import React, { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import Footer from "../../components/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Chip, Skeleton, TextField, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Navigation from "../../components/temp/Navigation";
import API_URL from "../../constants";

const WorkshopsPage = () => {
  const [data, setData] = useState([]);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const name = "workshop";

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    fetch(API_URL + name).then((response) => {
      response.json().then((data) => {
        setData(data);
      });
      response.ok && setLoading(false);
    });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = data.filter((item) =>
    item.tags?.some((tag) =>
      tag.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="container">
      <Navigation name="Workshops" />
      <div
        className="main"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Workshops
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
          }}
        >
          <TextField
            label="Search by tag or status"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 5,
              },
              "& .MuiInputLabel-root": {
                color: "#006789",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#006789",
              },
              //background
              "& .MuiOutlinedInput-input": {
                backgroundColor: "#fff",
                borderRadius: 5,
              },
            }}
          />
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Skeleton variant="rounded" />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {filteredData.map((workshop, index) => (
                <Accordion
                  key={index}
                  sx={{
                    boxShadow:
                      "0px 3px 1px -2px rgba(224,224,224,1),0px 2px 2px 0px rgba(224,224,224,1),0px 1px 5px 0px rgba(224,224,224,1)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Tooltip title={workshop.website} placement="top">
                        <Link
                          to={workshop.website}
                          target="_blank"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            textDecoration:
                              hoverIndex === index ? "underline" : "none",
                            color: "#006789",
                            gap: "5px",
                          }}
                          onMouseEnter={() => setHoverIndex(index)}
                          onMouseLeave={() => setHoverIndex(null)}
                        >
                          <Typography>{workshop.name}</Typography>
                          <FiExternalLink size={20} />
                        </Link>
                      </Tooltip>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {workshop.tags.map((tag) => (
                          <Chip key={tag} label={tag} size="small" />
                        ))}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: "#595959" }}>
                      {workshop.description}
                    </Typography>
                  </AccordionDetails>
                  <Accordion
                    sx={{
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Past workshops</Typography>
                    </AccordionSummary>
                    {workshop.past.length === 0 ? (
                      <AccordionDetails>
                        <Typography sx={{ color: "#8A8A8A" }}>
                          No past workshops
                        </Typography>
                      </AccordionDetails>
                    ) : (
                      <>
                        {workshop.past.map((event) => (
                          <AccordionDetails key={event._id}>
                            <Link
                              to={event.url}
                              target="_blank"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                color: "#006789",
                                gap: "5px",
                              }}
                            >
                              <Typography>{event.name}</Typography>
                              <FiExternalLink size={20} />
                            </Link>
                          </AccordionDetails>
                        ))}
                      </>
                    )}
                  </Accordion>
                  <Accordion
                    sx={{
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>Upcoming workshops</Typography>
                    </AccordionSummary>
                    {workshop.upcoming.length === 0 ? (
                      <AccordionDetails>
                        <Typography sx={{ color: "#8A8A8A" }}>
                          No upcoming workshops
                        </Typography>
                      </AccordionDetails>
                    ) : (
                      <>
                        {workshop.upcoming.map((event) => (
                          <AccordionDetails key={event._id}>
                            <Link
                              to={event.url}
                              target="_blank"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                color: "#006789",
                                gap: "5px",
                              }}
                            >
                              <Typography>{event.name}</Typography>
                              <FiExternalLink size={20} />
                            </Link>
                          </AccordionDetails>
                        ))}
                      </>
                    )}
                  </Accordion>
                </Accordion>
              ))}
            </Box>
          )}
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default WorkshopsPage;
