import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, LinearProgress } from "@mui/material";
import API_URL from "../constants";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  border: "2px solid #grey",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  maxHeight: "95%",
  overflowY: "auto",
  overflowX: "auto",

  "@media (max-width: 600px)": {
    maxWidth: "95%",
  },
};

export default function DeleteEntry(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const states = Array(props.entries.length).fill("");
  const [redirect, setRedirect] = useState(false);
  const [checking, setChecking] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleOpen();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const getState = (index) => {
    return states[index];
  };

  async function deleteEntry(event) {
    event.preventDefault();

    setLoading(true);
    const data = new FormData();

    if (props.type) {
      data.append("type", props.type);
      data.append("indexType", props.indexTypeConference);
      data.append("id", props._id);
    } else {
      props.entries.forEach((entry, index) => {
        getState(index) === ""
          ? data.append(entry.name, "" + props.data[entry.name])
          : data.append(entry.name, "" + getState(index));
      });
    }

    const response = await fetch(
      API_URL +
        (props.resources ? "resources/" : "") +
        props.name +
        "/" +
        (!props.type ? props.data._id : "delete-type"),
      {
        method: "DELETE",
        body: data,
      }
    );
    if (response.ok) {
      setRedirect(true);
      setOpenSnackbar(true);
      setLoading(false);
      props.onSuccess(); // Appel à onSuccess pour rafraîchir les données
    }
  }

  if (redirect) {
    handleClose();
    setRedirect(false);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", right: "8px", top: "8px" }}
            >
              <CloseIcon />
            </IconButton>
            <form
              onSubmit={(event) => {
                if (checking === "delete") {
                  deleteEntry(event);
                } else {
                  //annule submit
                  event.preventDefault();
                }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Typography
                  id="transition-modal-description"
                  variant="h6"
                  component="h2"
                >
                  Are you sure you want to delete this entry?
                </Typography>
                <Typography
                  id="transition-modal-description"
                  variant="subtitle1"
                >
                  To confirm, type "delete" in the box below
                </Typography>
                <TextField
                  id="filled-basic"
                  variant="outlined"
                  type="text"
                  placeholder="delete"
                  style={{ color: "#f5f5f5" }}
                  onChange={(event) => {
                    setChecking(event.target.value);
                  }}
                />
                {checking === "delete" ? (
                  <Button variant="contained" color="error" type="submit">
                    Delete
                  </Button>
                ) : (
                  <Button variant="contained" color="error" disabled>
                    Delete
                  </Button>
                )}
              </Box>
              {loading && <LinearProgress />}
            </form>
          </Box>
        </Fade>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Entry deleted successfully ! Refresh the page to see the changes.
        </Alert>
      </Snackbar>
    </div>
  );
}
